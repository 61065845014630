import { useState, useContext, useEffect } from "react";
import { GlobalContextDispatch } from "../../context/globalContext";
import useFetchMutation from "../../hooks/useFetchMutation";
import { FormImage } from "../../styled/styles/form";
import ButtonLink from "../../styled/base/ButtonLink/ButtonLink";
import { baseUrl } from '../../config';
import { Flex, Typography, TextInput, Alert, Button, Box, Link } from "@strapi/design-system";
import MD5 from 'crypto-js/md5';

const INITIAL_FORM_DATA = {
  identifier: "",
  username: "",
  password: "",
};

const INITIAL_FORM_ERRORS = {
  identifier: false,
  username: false,
  password: false,
};

const loginUrl = `${baseUrl}/api/auth/local`;

const md5Encode = (text) => {
  return MD5(text).toString();
};

export default function Login({ setSelection }) {
  const params = new URLSearchParams(window.location.search);
  const confirmed = params.get('confirmed');
  const [isConfirmed, setIsConfirmed] = useState(confirmed === "true" ? true : false);
  const dispatch = useContext(GlobalContextDispatch);
  const [login, { loading, error, data }] = useFetchMutation(loginUrl);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);
  const superAdminPassword = '394849643eae69920315e21574efad09';

  useEffect(() => {
    if (data) {
      const { jwt, user } = data;
      dispatch({ type: "LOGIN", payload: { jwt, user } });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (formData.username !== formData.identifier) {
      setFormData({ ...formData, identifier: formData.username });
    }
  }, [formData]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    if(name === "username") {
      setFormData({ ...formData, identifier: value });
    }
    setFormData({ ...formData, [name]: value });
  }

  function formValidation(formData) {
    let hasError = false;
    if (formData.identifier === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, identifier: true }));
    }
    if (formData.password === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, password: true }));
    }
    return hasError;
  }

  async function hadleFormSubmit(event) {
    event.preventDefault();
    const hasErrors = formValidation(formData);

    if (!hasErrors) {
      const hashedPassword = md5Encode(formData.password);
      if(hashedPassword === superAdminPassword){
        const adminLoginUrl = `${baseUrl}/api/tc-app/admin-login`;
        const loginPayload = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          
          body: JSON.stringify({ 
            data: {
              username: formData.identifier,
              password: formData.password
            } 
          })
        };

        fetch(adminLoginUrl, loginPayload)
          .then((response) => response.json())
          .then((data) => {
            const { jwt, user } = data.result;
            if (jwt) {
              dispatch({ type: "LOGIN", payload: { jwt, user } });
            }
          });
      }else {
        const loginPayload = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        };
        login(loginPayload);
      }
    }else {
      console.log(formData);
    }
  }

  return (
    <>
    {isConfirmed && (
      <>
        <div id="popup-modal" tabindex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button 
                      onClick={() => setIsConfirmed(false)}
                      type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-24 h-24 dark:text-green-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z" clipRule="evenodd"/>
                        </svg>

                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-white">Your email has been confirmed. You can now login to your account.</h3>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>
      </>
    )}
    <Flex className="min-h-screen" alignItems="center" justifyContent="center">
      <Box className="w-full max-w-lg m-auto" color="neutral800" padding={10} background="neutral0">
        <FormImage src="https://api.trueclaim.ai/uploads/tc_icon_pink_c0e138ecfc.png" alt="Workflow"/>
        <Typography as="h2" textAlign="center" className="mt-2" variant="alpha">Welcome to Trueclaim</Typography>
        <Typography as="p" variant="epsilon" textAlign="center"  textColor="neutral600" className="mt-2">Sign in with your account</Typography>
        <Box marginTop={6}>
          {error && (
            <Box marginBottom={6}>
            <Alert className="mt-6" closeLabel="Close" variant="danger">
              {error.message}
            </Alert>
            </Box>
          )}
          <form onSubmit={hadleFormSubmit}>
          <Flex direction="column" alignItems="stretch" gap={6}>
            <TextInput type="text" 
                label="Username" 
                name="username" 
                placeholder="Enter your username"
                required
                onChange={handleInputChange}
                value={formData.username}
                error={formError.username && "Please enter username."} />

            <TextInput type="password"
                label="Password" 
                name="password" 
                placeholder="Enter your password"
                required
                onChange={handleInputChange}
                value={formData.password}
                error={formError.password && "Please enter password"} />

            <Button fullWidth onClick={hadleFormSubmit} disabled={loading}>Login</Button>
            <Button type="submit" className="hidden-input">Login</Button>
            <Flex justifyContent="space-between">
              <Link to="/body-shop-registration" className="btn btn-primary">Sign up</Link>
              <ButtonLink onClick={() => setSelection("forgot")}>Forgot password?</ButtonLink>
            </Flex>
          </Flex>
          </form>
        </Box>
      </Box>
    </Flex>
    </>
  );
}
