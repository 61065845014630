import React, { useEffect, useState, useContext, useRef } from "react";
import socket from "../../socketio";
import parse from "html-react-parser";
import { 
  Button, 
  Tooltip, 
  SingleSelect, 
  SingleSelectOption,
  ModalLayout,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Typography,
  TextInput,
  Box,
  Accordion,
  AccordionToggle,
  AccordionContent,
  Table, Thead, Tr, Th, Tbody, Td
} from '@strapi/design-system';
import { Download, Envelop, Clock, Cog, Mail, Plus, PaperPlane } from '@strapi/icons';
import { GlobalContextState } from "../../context/globalContext";
import { baseUrl } from "../../config";
import { useParams } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Adas = ({ 
    adasReportData,
    setAdasReportData,
    positionStatement, 
    isSuperAdmin,
    sendEmailReport,
    isSendingEmail,
    setIsSendingEmail,
    toggleDrawer,
    dataRepairBms,
    bmsProfileType
  }) => {
  const token = useContext(GlobalContextState).token;
  const user = useContext(GlobalContextState).user;
  const [estimaticAutomation, setEstimaticAutomation] = useState(false);
  const { urlkey } = useParams();
  const downloadPdfUrl = `${baseUrl}/api/tc-app/download-adas-report/${urlkey}`;
  const saveReportUrl = `${baseUrl}/api/tc-app/edit-post-est-report`;
  const sendToEstimaticsUrl = `${baseUrl}/api/tc-app/send-to-estimatics`;
  const [showSafety, setShowSafety] = useState(true);
  const [addOperation, setAddOperation] = useState(false);
  const [shareReport, setShareReport] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSendingToEstimatics, setIsSendingToEstimatics] = useState(false);
  const [openBottomNotification, setOpenBottomNotification] = useState(false);
  const [bottomNotificationSeverity, setBottomNotificationSeverity] = useState("success");
  const [bottomNotificationMessage, setBottomNotificationMessage] = useState("");
  const [disableDownloadReport, setDisableDownloadReport] = useState(false);
  
  const [addOperationFormData, setAddOperationFormData] = useState({
    operationType: "adas_operation",
    name: "",
    procedureType: "",
    systems: "",
    triggers: [''],
    link: "",
    price: "",
    labor_hours: "",
    labor_type: "",
  });

  // Number of total operations for each section
  const [totalAdasOperations, setTotalAdasOperations] = useState(0);
  const [totalSafetyOperations, setTotalSafetyOperations] = useState(0);
  const [totalSteeringOperations, setTotalSteeringOperations] = useState(0);
  const [totalManualLines, setTotalManualLines] = useState(0);

  const [activeTotalAdasOperations, setActiveTotalAdasOperations] = useState(0);
  const [activeTotalSafetyOperations, setActiveTotalSafetyOperations] = useState(0);
  const [activeTotalSteeringOperations, setActiveTotalSteeringOperations] = useState(0);
  const [activeTotalManualLines, setActiveTotalManualLines] = useState(0);

  const [totalLaborHours, setTotalLaborHours] = useState(0);
  const [totalLaborHoursSummary, setTotalLaborHoursSummary] = useState({});
  const [totalProjectedPrice, setTotalProjectedPrice] = useState(0);
  const [totalProjectedPriceSummary, setTotalProjectedPriceSummary] = useState({});
  const [expandedAdasSystem, setExpandedAdasSystem] = useState(false);
  const [expandedDamageLines, setExpandedDamageLines] = useState(false);

  const stickyRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        const stickyPosition = stickyRef.current.getBoundingClientRect().top;
        if (stickyPosition <= 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Clean up the scroll event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if(user.automation){
      setEstimaticAutomation(true);
    }
  }, [user.automation]);

  useEffect(() => {
    // Calculate the number of operations for each section
    reCalculateReportData();
    if(!adasReportData.vehicleName) {
      setDisableDownloadReport(true);
    }
  }, [adasReportData]);

  useEffect(() => {
    socket.on(`claim-post-report-update-${urlkey}`, (data) => {
      console.log("Claim post report updated", data);
      setAdasReportData(data);
      setBottomNotificationSeverity("success");
      setBottomNotificationMessage("Post-Estimate report updated");
      handleOpenBottomNotification();
    });

    return () => {
      socket.off(`claim-post-report-update-${urlkey}`);
    }
  }, []);

  const handleOpenBottomNotification = () => {
    setOpenBottomNotification(true);
  };

  const handleCloseBottomNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenBottomNotification(false);
  };

  const showHideSafetyOperations = () => {
    setShowSafety(!showSafety);
    const safetyOperations = document.querySelectorAll(".js-safety-operations");
    safetyOperations.forEach((section) => {
      section.classList.toggle("hidden");
    });
  };

  const getTriggerLinesHtml = (triggerLines) => {
    let triggerLinesHtml = "";
    let triggerLinesMap = new Map();
    triggerLines.forEach((line) => {
      if (triggerLinesMap.has(line.description)) {
        triggerLinesMap.get(line.description).push(line);
      } else {
        triggerLinesMap.set(line.description, [line]);
      }
    });
    triggerLinesMap.forEach((lines, key) => {
      let lineNumbers = lines.map((line) => line.no).join(",");
      if (!lineNumbers) {
        lineNumbers = lines.map((line) => line.lineNumber).join(",");
      }
      if(lineNumbers) {
        triggerLinesHtml += `<li>${key} is involved (Line ${lineNumbers})</li>`;
      } else {
        triggerLinesHtml += `<li>${key} is involved</li>`;
      }
    });
    return parse(triggerLinesHtml);
  };

  const getTriggersHtml = (triggers) => {
    let triggerLinesHtml = "";
    triggers.forEach((trigger) => {
      triggerLinesHtml += `<li>${trigger}</li>`;
    });
    return parse(triggerLinesHtml);
  };

  const downloadPdf = async () => {
    setIsDownloading(true);
    console.log("Download PDF");
    const pdfUrl = await generatePdfUrl();
    window.open(pdfUrl, "_blank");
    setIsDownloading(false);
  }

  const shareEmailReport = async () => {
    setIsSendingEmail(true);
    const shareReportError = document.getElementById("share-report-error");
    const emails = document.getElementById("emails_share").value;
    shareReportError.classList.add("hidden");
    let canSubmit = false
    if(emails) {
      console.log("Emails", emails);
      const emailList = emails.split(",");
      canSubmit = emailList.every((email) => {
        return email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
      });
    }
    if(canSubmit) {
      await sendEmailReport(emails, urlkey);
      setShareReport(false);
    }else {
      shareReportError.classList.remove("hidden");
    }
    setIsSendingEmail(false);
  }

  const generatePdfUrl = async () => {
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(downloadPdfUrl, fetchOptions);
      const data = await response.json();
      if(data.status === "success") {
        return data.link;
      } else {
        alert("Error downloading PDF");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const saveReportChanges = () => {
    console.log("Saving Report Changes");
    // Call API to save the report changes
    const formData = {
      tcClaimId: urlkey,
      report: adasReportData
    }
    const submitReport = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    }
    fetch(saveReportUrl, submitReport)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        console.log("Report saved");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const sendToEstimatics = () => {
    console.log("Sending to Estimatics");
    // Call API to send to Estimatics
    const formData = {
      tcClaimId: urlkey,
      report: adasReportData
    }
    const sendToEstimatics = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    }
    setIsSendingToEstimatics(true);
    fetch(sendToEstimaticsUrl, sendToEstimatics)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if(data.status === "success") {
          setBottomNotificationSeverity("success");
          setBottomNotificationMessage("Post-Estimate report sent to Estimatic");
          handleOpenBottomNotification();
        } else {
          setBottomNotificationSeverity("error");
          if(data.message) {
            setBottomNotificationMessage(data.message);
          } else {
            setBottomNotificationMessage("Error sending post-estimate report to Estimatic");
          }
          handleOpenBottomNotification();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setBottomNotificationSeverity("error");
        if(error.message) {
          setBottomNotificationMessage(error.message);
        } else {
          setBottomNotificationMessage("Error sending post-estimate report to Estimatic");
        }
        handleOpenBottomNotification();
      })
      .finally(() => {
        setIsSendingToEstimatics(false);
      });
  }

  const addNewOperation = () => {
    setAddOperation(true);
  }

  const handleAddOperationFormChange = (name, value) => {
    setAddOperationFormData({ ...addOperationFormData, [name]: value });
  }

  const handleTriggersChange = (index, value) => {
    console.log("Triggers", index, value);
    const triggers = [...addOperationFormData.triggers];
    triggers[index] = value;
    setAddOperationFormData({ ...addOperationFormData, triggers });
  }

  const handleAddTrigger = () => {
    const triggers = [...addOperationFormData.triggers];
    triggers.push('');
    setAddOperationFormData({ ...addOperationFormData, triggers });
  }

  const handleAddOperationFormSubmit = () => {
    console.log("Add Operation Form Data", addOperationFormData);
    // Add operation to ADAS Report depending on the operation type
    if(addOperationFormData.operationType === "adas_operation") {
      if(!adasReportData.adasOperations) {
        adasReportData.adasOperations = [];
      }
      const operation = {
        name: addOperationFormData.name,
        procedureType: addOperationFormData.procedureType,
        systems: addOperationFormData.systems,
        triggers: addOperationFormData.triggers,
        externalLinks: addOperationFormData.link ? [{url: addOperationFormData.link, name: "Documentation"}] : [],
        price: addOperationFormData.price,
        isCustom: true,
      }
      if(addOperationFormData.labor_hours) {
        operation.lbr_hrs = addOperationFormData.labor_hours;
      }
      if(addOperationFormData.labor_type) {
        operation.lbr_type = addOperationFormData.labor_type;
      }
      adasReportData.adasOperations.push(operation);
    }
    if(addOperationFormData.operationType === "steering_operation") {
      if(!adasReportData.steeringOperations) {
        adasReportData.steeringOperations = [];
      }
      const operation = {
        name: addOperationFormData.name,
        procedureType: addOperationFormData.procedureType,
        externalLinks: addOperationFormData.link ? [{url: addOperationFormData.link, name: "Documentation"}] : [],
        price: addOperationFormData.price,
        isCustom: true,
      }
      if(addOperationFormData.labor_hours) {
        operation.lbr_hrs = addOperationFormData.labor_hours;
      }
      if(addOperationFormData.labor_type) {
        operation.lbr_type = addOperationFormData.labor_type;
      }
      adasReportData.steeringOperations.push(operation);
    }
    if(addOperationFormData.operationType === "safety_operation") {
      if(!adasReportData.safetyOperations) {
        adasReportData.safetyOperations = [];
      }
      const operation = {
        name: addOperationFormData.name,
        procedureType: addOperationFormData.procedureType,
        systems: addOperationFormData.systems,
        triggers: addOperationFormData.triggers,
        externalLinks: addOperationFormData.link ? [{url: addOperationFormData.link, name: "Documentation"}] : [],
        price: addOperationFormData.price,
        isCustom: true,
      }
      if(addOperationFormData.labor_hours) {
        operation.lbr_hrs = addOperationFormData.labor_hours;
      }
      if(addOperationFormData.labor_type) {
        operation.lbr_type = addOperationFormData.labor_type;
      }
      adasReportData.safetyOperations.push(operation);
    }
    if(addOperationFormData.operationType === "guide_line") {
      if(!adasReportData.manualLines) {
        adasReportData.manualLines = [];
      }
      const manualLine = {
        line: addOperationFormData.name,
        lbr_hrs: addOperationFormData.labor_hours,
        lbr_type: addOperationFormData.labor_type,
        price: addOperationFormData.price,
      }
      adasReportData.manualLines.push(manualLine);
    }

    reCalculateReportData();
    // Close the add operation form
    setAddOperation(false);
    saveReportChanges();
  }

  const reCalculateReportData = () => {
    console.log("Re-calculating Report Data");
    // Recalculate the total number of operations
    let activeTotalAdasOperations = 0;
    let newTotalAdasOperations = 0
    if(adasReportData.adasOperations) {
      //count the number of operations that are not removed
      activeTotalAdasOperations = adasReportData.adasOperations.filter(operation => !operation.removed).length;
      newTotalAdasOperations = adasReportData.adasOperations.length
    }
    let activeTotalSafetyOperations = 0;
    let newTotalSafetyOperations = 0;
    if(adasReportData.safetyOperations) {
      activeTotalSafetyOperations = adasReportData.safetyOperations.filter(operation => !operation.removed).length;
      newTotalSafetyOperations = adasReportData.safetyOperations.length;
    }
    let activeTotalSteeringOperations = 0;
    let newTotalSteeringOperations = 0;
    if(adasReportData.steeringOperations) {
      activeTotalSteeringOperations = adasReportData.steeringOperations.filter(operation => !operation.removed).length;
      newTotalSteeringOperations = adasReportData.steeringOperations.length;
    } 
    let activeTotalManualLines = 0;
    let newTotalManualLines = 0;
    if(adasReportData.manualLines) {
      activeTotalManualLines = adasReportData.manualLines.filter(line => !line.removed).length;
      newTotalManualLines = adasReportData.manualLines.length;
    }
    setActiveTotalAdasOperations(activeTotalAdasOperations);
    setTotalAdasOperations(newTotalAdasOperations);
    setActiveTotalSafetyOperations(activeTotalSafetyOperations);
    setTotalSafetyOperations(newTotalSafetyOperations);
    setActiveTotalSteeringOperations(activeTotalSteeringOperations);
    setTotalSteeringOperations(newTotalSteeringOperations);
    setActiveTotalManualLines(activeTotalManualLines);
    setTotalManualLines(newTotalManualLines);

    setTotalLaborHours(0);
    setTotalLaborHoursSummary({});

    let newTotalLaborHours = 0;
    let newTotalLaborHoursSummary = {};

    // Calculate the total projected price by summing up the price of all operations
    let newTotalProjectedPrice = 0;
    let newTotalProjectedPriceSummary = {};
    if(adasReportData.adasOperations) {
      adasReportData.adasOperations.forEach((operation) => {
        if(!operation.removed && operation.price) {
          // Skip if price is not a number
          if(isNaN(operation.price)) {
            console.log("Price is not a number", operation.price);
          } else {
            newTotalProjectedPrice += parseFloat(operation.price);
            if(newTotalProjectedPriceSummary['ADAS Operations']) {
              newTotalProjectedPriceSummary['ADAS Operations'] += parseFloat(operation.price);
            } else {
              newTotalProjectedPriceSummary['ADAS Operations'] = parseFloat(operation.price);
            }
          }
        }
        if(!operation.removed && operation.lbr_hrs && !isNaN(operation.lbr_hrs) && operation.lbr_type) {
          newTotalLaborHours += parseFloat(operation.lbr_hrs);
          if(newTotalLaborHoursSummary[operation.lbr_type]) {
            newTotalLaborHoursSummary[operation.lbr_type] += parseFloat(operation.lbr_hrs);
          } else {
            newTotalLaborHoursSummary[operation.lbr_type] = parseFloat(operation.lbr_hrs);
          }
        }
      });
    }
    if(adasReportData.safetyOperations) {
      adasReportData.safetyOperations.forEach((operation) => {
        if(!operation.removed && operation.price) {
          // Skip if price is not a number
          if(isNaN(operation.price)) {
            console.log("Price is not a number", operation.price);
          } else {
            newTotalProjectedPrice += parseFloat(operation.price);
            if(newTotalProjectedPriceSummary['Safety Operations']) {
              newTotalProjectedPriceSummary['Safety Operations'] += parseFloat(operation.price);
            } else {
              newTotalProjectedPriceSummary['Safety Operations'] = parseFloat(operation.price);
            }
          }
        }
        if(!operation.removed && operation.lbr_hrs && !isNaN(operation.lbr_hrs) && operation.lbr_type) {
          newTotalLaborHours += parseFloat(operation.lbr_hrs);
          if(newTotalLaborHoursSummary[operation.lbr_type]) {
            newTotalLaborHoursSummary[operation.lbr_type] += parseFloat(operation.lbr_hrs);
          } else {
            newTotalLaborHoursSummary[operation.lbr_type] = parseFloat(operation.lbr_hrs);
          }
        }
      });
    }
    if(adasReportData.steeringOperations) {
      adasReportData.steeringOperations.forEach((operation) => {
        if(!operation.removed && operation.price) {
          // Skip if price is not a number
          if(isNaN(operation.price)) {
            console.log("Price is not a number", operation.price);
          } else {
            newTotalProjectedPrice += parseFloat(operation.price);
            if(newTotalProjectedPriceSummary['Steering Operations']) {
              newTotalProjectedPriceSummary['Steering Operations'] += parseFloat(operation.price);
            } else {
              newTotalProjectedPriceSummary['Steering Operations'] = parseFloat(operation.price);
            }
          }
        }
        if(!operation.removed && operation.lbr_hrs && !isNaN(operation.lbr_hrs) && operation.lbr_type) {
          newTotalLaborHours += parseFloat(operation.lbr_hrs);
          if(newTotalLaborHoursSummary[operation.lbr_type]) {
            newTotalLaborHoursSummary[operation.lbr_type] += parseFloat(operation.lbr_hrs);
          } else {
            newTotalLaborHoursSummary[operation.lbr_type] = parseFloat(operation.lbr_hrs);
          }
        }
      });
    }
    if(adasReportData.manualLines) {
      adasReportData.manualLines.forEach((line) => {
        if(!line.removed && line.price) {
          // Skip if price is not a number
          if(isNaN(line.price)) {
            console.log("Price is not a number", line.price);
          } else {
            newTotalProjectedPrice += parseFloat(line.price);
            if(newTotalProjectedPriceSummary['Guidelines']) {
              newTotalProjectedPriceSummary['Guidelines'] += parseFloat(line.price);
            } else {
              newTotalProjectedPriceSummary['Guidelines'] = parseFloat(line.price);
            }
          }
        }
        if(!line.removed && line.lbr_hrs && !isNaN(line.lbr_hrs) && line.lbr_type) {
          console.log(line.line)
          newTotalLaborHours += parseFloat(line.lbr_hrs);
          if(newTotalLaborHoursSummary[line.lbr_type]) {
            newTotalLaborHoursSummary[line.lbr_type] += parseFloat(line.lbr_hrs);
          } else {
            newTotalLaborHoursSummary[line.lbr_type] = parseFloat(line.lbr_hrs);
          }
        }
      });
    }
    console.log("New Total Projected Price", newTotalProjectedPrice);
    console.log("New Total Projected Price Summary", newTotalProjectedPriceSummary);
    console.log("New Total Labor Hours", newTotalLaborHours);
    console.log("New Total Labor Hours Summary", newTotalLaborHoursSummary);
    setTotalProjectedPrice(roundToTwoDecimals(newTotalProjectedPrice));
    setTotalProjectedPriceSummary(newTotalProjectedPriceSummary);
    setTotalLaborHours(roundToTwoDecimals(newTotalLaborHours));
    setTotalLaborHoursSummary(newTotalLaborHoursSummary);
  }

  const roundToTwoDecimals = (value) => {
    const roundedValue = parseFloat(value.toFixed(2));
    return roundedValue;
  }

  const removeAdasEst = (type) => {
    const operations = [...adasReportData[type]];
    operations.forEach((operation) => {
      operation.removed = true;
    });
    setAdasReportData({ ...adasReportData, [type]: operations });
    reCalculateReportData();
    saveReportChanges();
  }

  const revertAdasEst = (type) => {
    const operations = [...adasReportData[type]];
    operations.forEach((operation) => {
      operation.removed = false;
    });
    setAdasReportData({ ...adasReportData, [type]: operations });
    reCalculateReportData();
    saveReportChanges();
  }


  const removeOperation = (type, index) => {
    console.log("Removing Operation", type, index);
    // Add the removed property to the operation
    const operations = [...adasReportData[type]];
    operations[index].removed = true;
    setAdasReportData({ ...adasReportData, [type]: operations });
    reCalculateReportData();
    console.log("ADAS Report Data", adasReportData);
    saveReportChanges();
  }

  const revertOperation = (type, index) => {
    const operations = [...adasReportData[type]];
    operations[index].removed = false;
    setAdasReportData({ ...adasReportData, [type]: operations });
    reCalculateReportData();
    saveReportChanges();
  }

  const updateOperation = (field, type, index, value) => {
    const operations = [...adasReportData[type]];
    operations[index][field] = value;
    setAdasReportData({ ...adasReportData, [type]: operations });
  }

  const saveOperation = (field, type, index, value) => {
    updateOperation(field, type, index, value);
    saveReportChanges();
  }

  const updateManualLine = (field, index, value) => {
    const manualLines = [...adasReportData.manualLines];
    manualLines[index][field] = value;
    setAdasReportData({ ...adasReportData, manualLines });
  }

  const saveManualLine = (field, index, value) => {
    updateManualLine(field, index, value);
    saveReportChanges();
  }

  if (!adasReportData || (adasReportData.status && adasReportData.status === "failed")) {
    return <div>Error getting ADAS report</div>;
  }

  function scrollToElement(id) {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.getBoundingClientRect().top + window.scrollY - 180;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  }

  return (
    <div className="leading-6 dark">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openBottomNotification}
        autoHideDuration={6000}
        onClose={handleCloseBottomNotification}
      >
        <Alert
          onClose={handleCloseBottomNotification}
          severity={bottomNotificationSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {bottomNotificationMessage}
        </Alert>
      </Snackbar>
      <div className="lg:m-4 block items-center justify-center rounded-lg border-2 border-gray-200 bg-gray-100 bg-strapi-neutral-800  p-6">
        <div className="flex grow flex-col">
          <div className="flex items-center justify-between">
            <div className="flex flex-wrap items-center justify-start text-sm md:flex">
              {estimaticAutomation && (
                <span className="mb-2 mr-2">
                  <Button
                    size="M"
                    startIcon={<PaperPlane />}
                    onClick={() => sendToEstimatics()}
                    loading={isSendingToEstimatics}
                  >
                    {isSendingToEstimatics ? "Sending..." : "Send to Estimatics"}
                  </Button>
                </span>
              )}
              <span className="mb-2 mr-2">
                <Button
                  size="M"
                  onClick={() => addNewOperation()}
                  startIcon={<Plus />}
                >
                  Add Operation
                </Button>
              </span>
              <span className="mb-2 mr-2">
                <Button
                  size="M"
                  onClick={() => setShareReport(true)}
                  startIcon={<Mail />}
                >
                  Share Report
                </Button>
              </span>
              <span className="mb-2 mr-2">
                <Button
                  onClick={downloadPdf}
                  loading={isDownloading}
                  startIcon={<Download />}
                  size="M"
                  disabled={disableDownloadReport}
                >
                  Download Report
                </Button>
              </span>
            </div>
            <div className="rounded-lg border-2 bg-gray-700 p-5 md:max-w-xs">
              <div className="inline-flex items-center rounded bg-red-200 px-3 py-1 text-sm font-semibold text-red-900">
                <svg
                  className="mr-1 h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 17 20"
                >
                  <path d="M7.958 19.393a7.7 7.7 0 0 1-6.715-3.439c-2.868-4.832 0-9.376.944-10.654l.091-.122a3.286 3.286 0 0 0 .765-3.288A1 1 0 0 1 4.6.8c.133.1.313.212.525.347A10.451 10.451 0 0 1 10.6 9.3c.5-1.06.772-2.213.8-3.385a1 1 0 0 1 1.592-.758c1.636 1.205 4.638 6.081 2.019 10.441a8.177 8.177 0 0 1-7.053 3.795Z"></path>
                </svg>
                Attention
              </div>
              <div className="flex items-center pt-2">
                <label className="relative inline-flex cursor-pointer">
                  <input
                    className="peer sr-only"
                    type="checkbox"
                    checked={showSafety}
                    onChange={() => showHideSafetyOperations()}
                  />
                  <div className="peer h-4 w-9 rounded-full bg-gray-300 after:absolute after:bottom-[2px] after:left-[2px] after:right-[2px] after:top-[2px] after:h-3 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                </label>
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Vehicle has sustained collision damage
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={stickyRef} id="sticky-parent" className="flex-row lg:px-2 md:flex">
        {totalAdasOperations > 0 && (
          <div className="relative lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0">
            <div className="cursor-pointer" onClick={() => scrollToElement("adas-operations")}>
              {activeTotalAdasOperations === totalAdasOperations ? (
                <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {totalAdasOperations}
                </h1>
              ) : (
                <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {activeTotalAdasOperations }  <span className="text-2xl">/ {totalAdasOperations}</span>
                </h1>
              )}
              <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
                ADAS Operations
              </h1>
            </div>
            {activeTotalAdasOperations !== 0 ? (
              <button
                onClick={() => removeAdasEst('adasOperations')}
                className="absolute right-4 top-4 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5"
                  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            ) : (
              <button 
                onClick={() => revertAdasEst('adasOperations')}
                className="absolute right-4 top-4 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
              </button>
            )}
          </div>
        )}
        {totalSafetyOperations > 0 && (
          <div className="relative lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0">
            <div className="cursor-pointer" onClick={() => scrollToElement("safety-operations")}>
              {activeTotalSafetyOperations === totalSafetyOperations ? (
                <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {totalSafetyOperations}
                </h1>
              ) : (
                <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {activeTotalSafetyOperations }  <span className="text-2xl">/ {totalSafetyOperations}</span>
                </h1>
              )}
              <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
                Safety Operations
              </h1>
            </div>
            {activeTotalSafetyOperations !== 0 ? (
              <button
                onClick={() => removeAdasEst('safetyOperations')}
                className="absolute right-4 top-4 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5"
                  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            ) : (
              <button 
                onClick={() => revertAdasEst('safetyOperations')}
                className="absolute right-4 top-4 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
              </button>
            )}
          </div>
        )}
        {totalSteeringOperations > 0 && (
          <div className="relative lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0">
            <div className="cursor-pointer" onClick={() => scrollToElement("steering-operations")}>
              {activeTotalSteeringOperations === totalSteeringOperations ? (
                <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {totalSteeringOperations}
                </h1>
              ) : (
                <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {activeTotalSteeringOperations }  <span className="text-2xl">/ {totalSteeringOperations}</span>
                </h1>
              )}
              <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
                Steering Operations
              </h1>
            </div>
            {activeTotalSteeringOperations !== 0 ? (
              <button
                onClick={() => removeAdasEst('steeringOperations')}
                className="absolute right-4 top-4 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5"
                  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            ) : (
              <button 
                onClick={() => revertAdasEst('steeringOperations')}
                className="absolute right-4 top-4 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
              </button>
            )}
          </div>
        )}
        {totalManualLines > 0 && (
          <div className="relative lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0">
            <div className="cursor-pointer" onClick={() => scrollToElement("manual-lines")}>
              {activeTotalManualLines === totalManualLines ? (
                <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {totalManualLines}
                </h1>
              ) : (
                <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {activeTotalManualLines }  <span className="text-2xl">/ {totalManualLines}</span>
                </h1>
              )}
              <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
                Guidelines
              </h1>
            </div>
            {activeTotalManualLines !== 0 ? (
              <button
                onClick={() => removeAdasEst('manualLines')}
                className="absolute right-4 top-4 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5"
                  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            ) : (
              <button 
                onClick={() => revertAdasEst('manualLines')}
                className="absolute right-4 top-4 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
              </button>
            )}
          </div>
        )}
        {isSuperAdmin && (
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("body-and-frame")}>
          <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
            4
          </h1>
          <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
            Repair Procedures
          </h1>
        </div>
        )}
        {positionStatement && positionStatement.length > 0 && (
          <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("position-statement")}>
            <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
              {positionStatement.length}
            </h1>
            <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
              Position Statement
            </h1>
          </div>
        )}
      </div>

      <div id="sticky-child" className={`fixed transition-all flex-row lg:px-2 top-[73px] left-0 right-0 z-40 pb-4 dark:bg-gray-800  ${isSticky ? "flex" : "hidden"}`}>
        {totalAdasOperations > 0 && (
        <div className="flex-1 flex items-center cursor-pointer lg:mx-2 my-4 block rounded-lg border border-gray-200 bg-white py-2 px-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0" onClick={() => scrollToElement("adas-operations")}>
          {activeTotalAdasOperations === totalAdasOperations ? (
            <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
              {totalAdasOperations} <br/> ADAS Operations
            </h1>
          ) : (
            <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
              {activeTotalAdasOperations } / {totalAdasOperations} <br/> ADAS Operations
            </h1>
          )}
        </div>
        )}
        {totalSafetyOperations > 0 && (
        <div className="flex-1 flex items-center cursor-pointer js-safety-operations lg:mx-2 my-4 block rounded-lg border border-gray-200 bg-white py-2 px-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("safety-operations")}>
          {activeTotalSafetyOperations === totalSafetyOperations ? (
            <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
              {totalSafetyOperations} <br/> Safety Operations
            </h1>
          ) : (
            <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
              {activeTotalSafetyOperations } / {totalSafetyOperations} <br/> Safety Operations
            </h1>
          )}
        </div>
        )}
        {totalSteeringOperations > 0 && (
        <div className="flex-1 flex items-center cursor-pointer lg:mx-2 my-4 block rounded-lg border border-gray-200 bg-white py-2 px-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("steering-operations")}>
          {activeTotalSteeringOperations === totalSteeringOperations ? (
            <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
              {totalSteeringOperations} <br/> Steering Operations
            </h1>
          ) : (
            <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
              {activeTotalSteeringOperations } / {totalSteeringOperations} <br/> Steering Operations
            </h1>
          )}
        </div>
        )}
        {totalManualLines > 0 && (
        <div className="flex-1 flex items-center cursor-pointer lg:mx-2 my-4 block rounded-lg border border-gray-200 bg-white py-2 px-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("manual-lines")}>
          {activeTotalManualLines === totalManualLines ? (
            <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
              {totalManualLines} <br/> Guidelines
            </h1>
          ) : (
            <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
              {activeTotalManualLines } / {totalManualLines} <br/> Guidelines
            </h1>
          )}
        </div>
        )}
        {isSuperAdmin && (
        <div className="flex-1 flex items-center cursor-pointer lg:mx-2 my-4 block rounded-lg border border-gray-200 bg-white py-2 px-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("body-and-frame")}>
          <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
            4 <br/> Repair Procedures
          </h1>
        </div>
        )}
        {positionStatement && positionStatement.length > 0 && (
          <div className="flex-1 flex items-center cursor-pointer lg:mx-2 my-4 block rounded-lg border border-gray-200 bg-white py-2 px-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("position-statement")}>
            <h1 className="text-l max-w-full tracking-tight text-gray-500 dark:text-white">
            {positionStatement.length} <br/> Position Statement
            </h1>
          </div>
        )}
        <div className="flex-grow lg:mx-2 my-4 w-auto block rounded-lg border border-gray-200 bg-white py-2 px-2 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0 max-w-xl">
          <div class="flex flex-row flex-wrap items-center h-full">
            {estimaticAutomation && (
              <span className="mr-[10px]" style={{ width: "calc(25% - 10px)" }}>
                <Button
                  className="w-full justify-center py-2 custom-padding"
                  size="M"
                  onClick={() => sendToEstimatics()}
                  loading={isSendingToEstimatics}
                >
                  {isSendingToEstimatics ? "Sending..." : "Send to Estimatics"}
                </Button>
              </span>
            )}
            <span className="mr-[10px]" style={{ width: "calc(25% - 10px)" }}>
              <Button
                size="M"
                className="w-full justify-center custom-padding"
                onClick={() => addNewOperation()}
              >
                Add Operation
              </Button>
            </span>
            <span className="mr-[10px]" style={{ width: "calc(25% - 10px)" }}>
              <Button
                size="M"
                className="w-full justify-center custom-padding"
                onClick={() => setShareReport(true)}
              >
                Share Report
              </Button>
            </span>
            <span style={{ width: "25%" }}>
              <Button
                className="w-full justify-center custom-padding"
                onClick={downloadPdf}
                loading={isDownloading}
                size="M"
                disabled={disableDownloadReport}
              >
                Download Report
              </Button>
            </span>
            <div className="flex items-center w-full mt-2">
              <div className="inline-flex items-center rounded bg-red-200 px-3 py-1 text-sm font-semibold text-red-900 mr-4">
                <svg
                  className="mr-1 h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 17 20"
                >
                  <path d="M7.958 19.393a7.7 7.7 0 0 1-6.715-3.439c-2.868-4.832 0-9.376.944-10.654l.091-.122a3.286 3.286 0 0 0 .765-3.288A1 1 0 0 1 4.6.8c.133.1.313.212.525.347A10.451 10.451 0 0 1 10.6 9.3c.5-1.06.772-2.213.8-3.385a1 1 0 0 1 1.592-.758c1.636 1.205 4.638 6.081 2.019 10.441a8.177 8.177 0 0 1-7.053 3.795Z"></path>
                </svg>
                Attention
              </div>
              <label className="relative inline-flex cursor-pointer">
                <input
                  className="peer sr-only"
                  type="checkbox"
                  checked={showSafety}
                  onChange={() => showHideSafetyOperations()}
                />
                <div className="peer h-4 w-9 rounded-full bg-gray-300 after:absolute after:bottom-[2px] after:left-[2px] after:right-[2px] after:top-[2px] after:h-3 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
              </label>
              <label
                htmlFor="checked-checkbox"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Vehicle has sustained collision damage
              </label>
            </div>
          </div>
        </div>
      </div>

      {adasReportData && adasReportData.allAdasSystems && (
        <div className="adas-system-container lg:px-4 my-4">
          <Accordion expanded={expandedAdasSystem} onToggle={() => setExpandedAdasSystem(s => !s)}  id="acc-2" size="S">
            <AccordionToggle title={`ADAS Systems`} />
            <AccordionContent>
              <Box padding={4}>
                <div className="flex-row md:flex">
                  <ul className="max-w-full flex-1 list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400 md:mr-2">
                    {adasReportData.allAdasSystems
                      .slice(0, adasReportData.allAdasSystems.length / 2)
                      .map((adasSystem, index) => (
                        <li key={index}>{adasSystem}</li>
                      ))}
                  </ul>
                  <ul className="max-w-full flex-1 list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400 md:ml-2">
                    {adasReportData.allAdasSystems
                      .slice(adasReportData.allAdasSystems.length / 2)
                      .map((adasSystem, index) => (
                        <li key={index}>{adasSystem}</li>
                      ))}
                  </ul>
                </div>
              </Box>
            </AccordionContent>
          </Accordion>
        </div>
      )}
      {dataRepairBms && dataRepairBms.length > 0 && (
        <div className="adas-system-container lg:px-4 my-4">
          <Accordion expanded={expandedDamageLines} onToggle={() => setExpandedDamageLines(s => !s)}  id="acc-2" size="S">
            <AccordionToggle title={`Damage Lines`} />
            <AccordionContent>
              <Table
                className="tc-estimate-table"
                colCount="2"
                rowCount={dataRepairBms.length + 1}
                background="neutral200"
              >
                <Thead>
                  <Tr>
                    <Th style={{maxWidth: "150px"}}><Typography variant="omega">LineHeaderDesc</Typography></Th>
                    <Th style={{maxWidth: "150px"}}><Typography variant="omega">LineDesc</Typography></Th>
                    <Th><Typography variant="omega">PartNum</Typography></Th>
                    <Th><Typography variant="omega">PartPrice</Typography></Th>
                    <Th><Typography variant="omega">PartType</Typography></Th>
                    <Th><Typography variant="omega">LaborOperation</Typography></Th>
                    <Th><Typography variant="omega">LaborHours</Typography></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataRepairBms.map((item, index) => (
                    <Tr key={index}>
                      <Td>{ item.LineHeaderDesc }</Td>
                      <Td>{ item.LineDesc }</Td>
                      <Td>{ item.PartNum }</Td>
                      <Td>{ item.PartPrice }</Td>
                      <Td>{ item.PartType }</Td>
                      <Td>{ bmsProfileType[item.LaborOperation] ? bmsProfileType[item.LaborOperation] : item.LaborOperation }</Td>
                      <Td>{ item.LaborHours }</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </AccordionContent>
          </Accordion>
        </div>
      )}

      {totalAdasOperations > 0 && (
        <>
        <div id="adas-operations" className="flex flex-row items-center justify-between lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
          <h4 className={`flex item-center mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white ${activeTotalAdasOperations === 0 ? "line-through" : ""}`}>
            ADAS Operations
            {activeTotalAdasOperations === 0 && (
              <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
              </svg>
            )}
          </h4>
          {activeTotalAdasOperations !== 0 ? (
            <button 
              onClick={() => removeAdasEst('adasOperations')}
              className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
              <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5"
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          ) : (
            <button 
              onClick={() => revertAdasEst('adasOperations')}
              className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
              <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
            </button>
          )}
        </div>
        {adasReportData && adasReportData.adasOperations && adasReportData.adasOperations.length > 0 && activeTotalAdasOperations > 0 && (
          <>
          {adasReportData.adasOperations.map((adasOperation, index) => (
            <div
              key={index}
              id={`section-adas-operation-${index}`}
              className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
            >
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row">
                  <div className="flex space-x-4">
                    <h6 className={`mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white ${adasOperation.removed ? "line-through" : ""}`}>
                      {index + 1}. {adasOperation.name}
                    </h6>
                    {adasOperation.removed && (
                      <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                      </svg>
                    )}
                  </div>
                </div>
                <div className="flex">
                  {!adasOperation.removed ? (
                    <button
                      onClick={() => removeOperation('adasOperations', index)}
                      className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        fill="none"
                        className="h-4 w-4"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  ) : (
                    <button 
                      onClick={() => revertOperation('adasOperations', index)}
                      className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                      <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
                    </button>
                  )}
                  <button
                    onClick={() => toggleDrawer(true, `section-adas-operation-${index}`)}
                    className="flag-actions-adas ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#fff" height="20px" width="20px" version="1.1" id="Capa_1" viewBox="0 0 489 489" xmlSpace="preserve">
                      <g>
                        <g>
                          <path d="M454.3,31.6c-28.5-15.3-59.1-23.4-93.7-23.4c-40.7,0-81.5,11.2-120.2,21.4S166,50,130.4,50c-23.8,0-45.3-4.6-65.2-13.7    V20.4C65.2,9.2,56,0,44.8,0S24.4,9.2,24.4,20.4v448.2c0,11.2,9.2,20.4,20.4,20.4s20.4-9.2,20.4-20.4v-148    c20,6.9,41.2,10.5,64.2,10.5c40.7,0,81.5-11.2,120.2-20.4c38.7-10.2,74.4-20.4,110-20.4c27.5,0,52,6.1,74.4,18.3    c12.7,8.7,30.6-2.1,30.6-17.3V49.9C464.4,41.8,460.4,35.7,454.3,31.6z M423.7,258.8c-20.4-7.1-41.8-10.2-64.2-10.2    c-40.7,0-81.5,11.2-120.2,21.4s-74.4,20.4-110,20.4c-23.4,0-44.8-4.1-64.2-13.2V79.5c20.4,7.1,41.8,10.2,64.2,10.2    c40.7,0,81.5-11.2,120.2-21.4s74.4-20.4,110-20.4c23.4,0,44.8,4.1,64.2,13.2V258.8z"/>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
                <li>
                  <span className="font-bold">Calibration Type: </span>{" "}
                  {adasOperation.procedureType ? adasOperation.procedureType : "N/A"}
                </li>
                {adasOperation.systems && (
                  <li>
                    <span className="font-bold">Responsible For: </span>
                    {adasOperation.systems}
                  </li>
                )}
              </ul>
              <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                Per the {adasReportData.vehicleName} repair manual, the{" "}
                {adasOperation.name} necessitates the above mentioned procedure
                should any of the following occur:
              </p>
              <ul className="max-w-full list-inside list-decimal space-y-1 text-gray-500 dark:text-gray-400">
                {/* If trigger is not empty, split the trigger by comma and print every trigger */}
                {(adasOperation.triggerLines && adasOperation.triggerLines.length > 0 && (
                  getTriggerLinesHtml(adasOperation.triggerLines)
                ))}
                {(adasOperation.triggers && adasOperation.triggers.length > 0 && (
                  getTriggersHtml(adasOperation.triggers)
                ))}
              </ul>
              {adasOperation.externalLinks && adasOperation.externalLinks.length > 0 && (
                <div>
                  <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                    OEM Procedure Requirements and Instructions:
                  </p>
                  {adasOperation.externalLinks.map((link, index) => (
                    <div className="flex flex-row items-center ml-5 my-5" key={index}>
                      <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${link.url}`} alt="Manufacturer Procedure Instructions" width={100} height={100} />
                      <a href={link.url} target="_blank" rel="noreferrer" className="ml-5">
                        <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                          {link.name}
                        </span>
                      </a>
                    </div>
                  ))}
                </div>
              )}
              {(!adasOperation.externalLinks || adasOperation.externalLinks.length === 0) && (
                <div>
                  <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                    Please consult the below manufacturer-provided documentation for
                    complete instructions and requirements:
                  </p>
                  <div className="flex flex-row items-center ml-5 my-5">
                    <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${adasOperation.link}`} alt="Manufacturer Procedure Instructions" width={100} height={100} />
                    <a href={adasOperation.link} target="_blank" rel="noreferrer" className="ml-5">
                      <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                        Manufacturer Procedure Instructions
                      </span>
                    </a>
                  </div>
                  <div className="flex flex-row items-center ml-5 my-5">
                    <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${adasOperation.repairLink}`} alt="Manufacturer Mandated Requirement" width={100} height={100} />
                    <a href={adasOperation.repairLink} target="_blank" rel="noreferrer" className="ml-5">
                      <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                        Manufacturer Mandated Requirement
                      </span>
                    </a>
                  </div>
                </div>
              )}
              <div className="flex items-center justify-between">
                <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
                  &nbsp;
                </div>
                <div className="flex items-center justify-end space-x-5 pt-5">
                  <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                    <div className="flex rounded-lg border-transparent">
                      <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                        <Clock />
                      </span>
                      <input
                        id="price-price-ADAS Operations-Headlight Control Module-0"
                        className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                        type="number"
                        disabled={adasOperation.removed}
                        value={adasOperation.lbr_hrs ? adasOperation.lbr_hrs : ""}
                        onChange={(e) => updateOperation('lbr_hrs', 'adasOperations', index, e.target.value)}
                        onBlur={(e) => saveOperation('lbr_hrs', 'adasOperations', index, e.target.value)}
                      />
                    </div>
                    <span className="text-xs">Labor Hours</span>
                  </div>
                  <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                    <div className="flex rounded-lg border-transparent">
                      <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                        <Cog />
                      </span>
                      <select
                        id="price-price-ADAS Operations-Headlight Control Module-0"
                        disabled={adasOperation.removed}
                        className="bg-gray-50 w-28 p-2.5 pl-2 text-base leading-4 block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                        value={adasOperation.lbr_type ? adasOperation.lbr_type : ""}
                        onChange={(e) => updateOperation('lbr_type', 'adasOperations', index, e.target.value)}
                        onBlur={(e) => saveOperation('lbr_type', 'adasOperations', index, e.target.value)}
                      >
                        <option value=""></option>
                        <option value="Body">Body</option>
                        <option value="Mech">Mech</option>
                        <option value="Refinish">Refinish</option>
                      </select>
                    </div>
                    <span className="text-xs">Type</span>
                  </div>
                  <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                    <div className="flex rounded-lg border-transparent">
                      <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                        $
                      </span>
                      <input
                        className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                        type="number"
                        disabled={adasOperation.removed}
                        value={adasOperation.price ? adasOperation.price : ""}
                        onChange={(e) => updateOperation('price', 'adasOperations', index, e.target.value)}
                        onBlur={(e) => saveOperation('price', 'adasOperations', index, e.target.value)}
                      />
                    </div>
                    <span className="text-xs">Price</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </>
        )}
        </>
      )}

      {totalSafetyOperations > 0 && (
        <>
          <div id="safety-operations" className="flex flex-row items-center justify-between lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
            <h4 className={`flex item-center mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white ${activeTotalSafetyOperations === 0 ? "line-through" : ""}`}>
              Safety Operations
              {activeTotalSafetyOperations === 0 && (
                <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                </svg>
              )}
            </h4>
            {activeTotalSafetyOperations !== 0 ? (
              <button 
                onClick={() => removeAdasEst('safetyOperations')}
                className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5"
                  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            ) : (
              <button 
                onClick={() => revertAdasEst('safetyOperations')}
                className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
              </button>
            )}
          </div>
          {adasReportData && adasReportData.safetyOperations && adasReportData.safetyOperations.length > 0 && activeTotalSafetyOperations > 0 && (
            <>
            {adasReportData.safetyOperations.map((operation, index) => (
              <div
                key={index}
                id={`section-safety-operation-${index}`}
                className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
              >
                <div id={`section-safety-operation-${index}-incorrect`} className="flex flex-row items-center justify-between">
                  <div className="flex flex-row">
                    <div className="flex space-x-4">
                      <h6 className={`mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white ${operation.removed ? "line-through" : ""}`}>
                        {index + 1}. {operation.name}
                      </h6>
                      {operation.removed && (
                        <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    {!operation.removed ? (
                      <button
                        onClick={() => removeOperation('safetyOperations', index)}
                        className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                      >
                        <svg
                          fill="none"
                        className="h-4 w-4"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                    ) : (
                      <button
                        onClick={() => revertOperation('safetyOperations', index)}
                        className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                        <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
                      </button>
                    )}
                    <button
                      onClick={() => toggleDrawer(true, `section-safety-operation-${index}`)}
                      className="flag-actions-adas ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#fff" height="20px" width="20px" version="1.1" id="Capa_1" viewBox="0 0 489 489" xmlSpace="preserve">
                        <g>
                          <g>
                            <path d="M454.3,31.6c-28.5-15.3-59.1-23.4-93.7-23.4c-40.7,0-81.5,11.2-120.2,21.4S166,50,130.4,50c-23.8,0-45.3-4.6-65.2-13.7    V20.4C65.2,9.2,56,0,44.8,0S24.4,9.2,24.4,20.4v448.2c0,11.2,9.2,20.4,20.4,20.4s20.4-9.2,20.4-20.4v-148    c20,6.9,41.2,10.5,64.2,10.5c40.7,0,81.5-11.2,120.2-20.4c38.7-10.2,74.4-20.4,110-20.4c27.5,0,52,6.1,74.4,18.3    c12.7,8.7,30.6-2.1,30.6-17.3V49.9C464.4,41.8,460.4,35.7,454.3,31.6z M423.7,258.8c-20.4-7.1-41.8-10.2-64.2-10.2    c-40.7,0-81.5,11.2-120.2,21.4s-74.4,20.4-110,20.4c-23.4,0-44.8-4.1-64.2-13.2V79.5c20.4,7.1,41.8,10.2,64.2,10.2    c40.7,0,81.5-11.2,120.2-21.4s74.4-20.4,110-20.4c23.4,0,44.8,4.1,64.2,13.2V258.8z"/>
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
                <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
                  <li>
                    <span className="font-bold">Calibration Type: </span>{" "}
                    {operation.procedureType ? operation.procedureType : "N/A"}
                  </li>
                </ul>
                <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                  Per the {adasReportData.vehicleName} repair manual, the{" "}
                  {operation.name} necessitates the above mentioned procedure
                  should any of the following occur:
                </p>
                <ul className="max-w-full list-inside list-decimal space-y-1 text-gray-500 dark:text-gray-400">
                  <li>The vehicle has sustained collision damage</li>
                </ul>
                {operation.externalLinks && operation.externalLinks.length > 0 && (
                  <div>
                    <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                      OEM Procedure Requirements and Instructions:
                    </p>
                    {operation.externalLinks.map((link, index) => (
                      <div className="flex flex-row items-center ml-5 my-5" key={index}>
                        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${link.url}`} alt="Manufacturer Procedure Instructions" width={100} height={100} />
                        <a href={link.url} target="_blank" rel="noreferrer" className="ml-5">
                          <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                            {link.name}
                          </span>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
                    &nbsp;
                  </div>
                  <div className="flex items-center justify-end space-x-5 pt-5">
                    <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                      <div className="flex rounded-lg border-transparent">
                        <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                          <Clock />
                        </span>
                        <input
                          id="price-price-ADAS Operations-Headlight Control Module-0"
                          className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                          type="number"
                          disabled={operation.removed}
                          value={operation.lbr_hrs ? operation.lbr_hrs : ""}
                          onChange={(e) => updateOperation('lbr_hrs', 'safetyOperations', index, e.target.value)}
                          onBlur={(e) => saveOperation('lbr_hrs', 'safetyOperations', index, e.target.value)}
                        />
                      </div>
                      <span className="text-xs">Labor Hours</span>
                    </div>
                    <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                      <div className="flex rounded-lg border-transparent">
                        <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                          <Cog />
                        </span>
                        <select
                          disabled={operation.removed}
                          id="price-price-ADAS Operations-Headlight Control Module-0"
                          className="bg-gray-50 w-28 p-2.5 pl-2 text-base leading-4 block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                          value={operation.lbr_type ? operation.lbr_type : ""}
                          onChange={(e) => updateOperation('lbr_type', 'safetyOperations', index, e.target.value)}
                          onBlur={(e) => saveOperation('lbr_type', 'safetyOperations', index, e.target.value)}
                        >
                          <option value=""></option>
                          <option value="Body">Body</option>
                          <option value="Mech">Mech</option>
                          <option value="Refinish">Refinish</option>
                        </select>
                      </div>
                      <span className="text-xs">Type</span>
                    </div>
                    <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                      <div className="flex rounded-lg border-transparent">
                        <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                          $
                        </span>
                        <input
                          disabled={operation.removed}
                          className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                          type="number"
                          value={operation.price ? operation.price : ""}
                          onChange={(e) => updateOperation('price', 'safetyOperations', index, e.target.value)}
                          onBlur={(e) => saveOperation('price', 'safetyOperations', index, e.target.value)}
                        />
                      </div>
                      <span className="text-xs">Price</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </>
          )}
        </>
      )}

      {totalSteeringOperations > 0 && (
        <>
          <div id="steering-operations" className="flex flex-row items-center justify-between lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
            <h4 className={`flex item-center mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white ${activeTotalSteeringOperations === 0 ? "line-through" : ""}`}>
              Steering Operations
              {activeTotalSteeringOperations === 0 && (
                <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                </svg>
              )}
            </h4>
            {activeTotalSteeringOperations !== 0 ? (
              <button 
                onClick={() => removeAdasEst('steeringOperations')}
                className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5"
                  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            ) : (
              <button 
                onClick={() => revertAdasEst('steeringOperations')}
                className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
              </button>
            )}
          </div>
          {adasReportData && adasReportData.steeringOperations && adasReportData.steeringOperations.length > 0 && activeTotalSteeringOperations > 0 && (
            <>
            {adasReportData.steeringOperations.map((operation, index) => (
              <div
                key={index}
                id={`section-steering-operation-${index}`}
                className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
              >
                <div id={`section-steering-operation-${index}-incorrect`} className="flex flex-row items-center justify-between">
                  <div className="flex flex-row">
                    <h6 className={`mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white ${operation.removed ? "line-through" : ""}`}>
                      {index + 1}. {operation.name}
                    </h6>
                    {operation.removed && (
                      <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                      </svg>
                    )}
                  </div>
                  <div className="flex">
                    {!operation.removed ? (
                      <button
                        onClick={() => removeOperation('steeringOperations', index)}
                        className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                      >
                      <svg
                        fill="none"
                        className="h-4 w-4"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                      <span className="sr-only">Incorrect</span>
                      </button>
                    ) : (
                      <button
                        onClick={() => revertOperation('steeringOperations', index)}
                        className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                        <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
                      </button>
                    )}
                    <button
                      onClick={() => toggleDrawer(true, `section-steering-operation-${index}`)}
                      className="flag-actions-adas ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#fff" height="20px" width="20px" version="1.1" id="Capa_1" viewBox="0 0 489 489" xmlSpace="preserve">
                        <g>
                          <g>
                            <path d="M454.3,31.6c-28.5-15.3-59.1-23.4-93.7-23.4c-40.7,0-81.5,11.2-120.2,21.4S166,50,130.4,50c-23.8,0-45.3-4.6-65.2-13.7    V20.4C65.2,9.2,56,0,44.8,0S24.4,9.2,24.4,20.4v448.2c0,11.2,9.2,20.4,20.4,20.4s20.4-9.2,20.4-20.4v-148    c20,6.9,41.2,10.5,64.2,10.5c40.7,0,81.5-11.2,120.2-20.4c38.7-10.2,74.4-20.4,110-20.4c27.5,0,52,6.1,74.4,18.3    c12.7,8.7,30.6-2.1,30.6-17.3V49.9C464.4,41.8,460.4,35.7,454.3,31.6z M423.7,258.8c-20.4-7.1-41.8-10.2-64.2-10.2    c-40.7,0-81.5,11.2-120.2,21.4s-74.4,20.4-110,20.4c-23.4,0-44.8-4.1-64.2-13.2V79.5c20.4,7.1,41.8,10.2,64.2,10.2    c40.7,0,81.5-11.2,120.2-21.4s74.4-20.4,110-20.4c23.4,0,44.8,4.1,64.2,13.2V258.8z"/>
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
                <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
                  <li>
                    <span className="font-bold">Calibration Type: </span>{" "}
                    {operation.procedureType ? operation.procedureType : "N/A"}
                  </li>
                  {operation.trigger && (
                    <li>
                      <span className="font-bold">Reason: </span>{operation.trigger}
                    </li>
                  )}
                </ul>
                <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                  Per the {adasReportData.vehicleName} repair manual, the{" "}
                  {operation.name} necessitates the above mentioned procedure
                  should any of the following occur:
                </p>
                <ul className="max-w-full list-inside list-decimal space-y-1 text-gray-500 dark:text-gray-400">
                  <li>The vehicle has sustained collision damage</li>
                </ul>
                {operation.externalLinks && operation.externalLinks.length > 0 && (
                  <div>
                    <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                      OEM Procedure Requirements and Instructions:
                    </p>
                    {operation.externalLinks.map((link, index) => (
                      <div className="flex flex-row items-center ml-5 my-5" key={index}>
                        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${link.url}`} alt="Manufacturer Procedure Instructions" width={100} height={100} />
                        <a href={link.url} target="_blank" rel="noreferrer" className="ml-5">
                          <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                            {link.name}
                          </span>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
                    &nbsp;
                  </div>
                  <div className="flex items-center justify-end space-x-5 pt-5">
                    <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                      <div className="flex rounded-lg border-transparent">
                        <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                          <Clock />
                        </span>
                        <input
                          disabled={operation.removed}
                          id="price-price-ADAS Operations-Headlight Control Module-0"
                          className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                          type="number"
                          value={operation.lbr_hrs ? operation.lbr_hrs : ""}
                          onChange={(e) => updateOperation('lbr_hrs', 'steeringOperations', index, e.target.value)}
                          onBlur={(e) => saveOperation('lbr_hrs', 'steeringOperations', index, e.target.value)}
                        />
                      </div>
                      <span className="text-xs">Labor Hours</span>
                    </div>
                    <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                      <div className="flex rounded-lg border-transparent">
                        <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                          <Cog />
                        </span>
                        <select
                          disabled={operation.removed}
                          id="price-price-ADAS Operations-Headlight Control Module-0"
                          className="bg-gray-50 w-28 p-2.5 pl-2 text-base leading-4 block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                          value={operation.lbr_type ? operation.lbr_type : ""}
                          onChange={(e) => updateOperation('lbr_type', 'steeringOperations', index, e.target.value)}
                          onBlur={(e) => saveOperation('lbr_type', 'steeringOperations', index, e.target.value)}
                        >
                          <option value=""></option>
                          <option value="Body">Body</option>
                          <option value="Mech">Mech</option>
                          <option value="Refinish">Refinish</option>
                        </select>
                      </div>
                      <span className="text-xs">Type</span>
                    </div>
                    <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                      <div className="flex rounded-lg border-transparent">
                        <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                          $
                        </span>
                        <input
                          disabled={operation.removed}
                          className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                          type="number"
                          value={operation.price ? operation.price : ""}
                          onChange={(e) => updateOperation('price', 'steeringOperations', index, e.target.value)}
                          onBlur={(e) => saveOperation('price', 'steeringOperations', index, e.target.value)}
                        />
                      </div>
                      <span className="text-xs">Price</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </>
          )}
        </>
      )}

      {totalManualLines > 0 && (
        <>
          <div id="manual-lines" className="flex flex-row items-center justify-between lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
            <h4 className={`flex item-center mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white ${activeTotalManualLines === 0 ? "line-through" : ""}`}>
              Guidelines
              {activeTotalManualLines === 0 && (
                <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                </svg>
              )}
            </h4>
            {activeTotalManualLines !== 0 ? (
              <button 
                onClick={() => removeAdasEst('manualLines')}
                className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5"
                  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            ) : (
              <button 
                onClick={() => revertAdasEst('manualLines')}
                className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
              </button>
            )}
          </div>
          {adasReportData && adasReportData.manualLines && adasReportData.manualLines.length > 0 && activeTotalManualLines > 0 && (
            <>
              {adasReportData.manualLines.map((manualLine, index) => (
                <div
                  key={index}
                  id={`section-manualines-${index}`}
                  className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
                >
                  <div id={`section-manualines-${index}-incorrect`} className="flex flex-row items-center justify-between">
                    <div className="flex flex-row">
                      <h6 className={`mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white ${manualLine.removed ? "line-through" : ""}`}>
                        {index + 1}. {manualLine.line}
                      </h6>
                      {manualLine.removed && (
                        <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                        </svg>
                      )}
                    </div>
                    <div className="flex">
                      {!manualLine.removed ? (
                        <button
                          onClick={() => removeOperation('manualLines', index)}
                          className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                        >
                          <svg
                            fill="none"
                            className="h-4 w-4"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                          <span className="sr-only">Incorrect</span>
                        </button>
                      ) : (
                        <button
                          onClick={() => revertOperation('manualLines', index)}
                          className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                          <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
                        </button>
                      )}
                      <button
                        onClick={() => toggleDrawer(true, `section-manualines-operation-${index}`)}
                        className="flag-actions-adas ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#fff" height="20px" width="20px" version="1.1" id="Capa_1" viewBox="0 0 489 489" xmlSpace="preserve">
                          <g>
                            <g>
                              <path d="M454.3,31.6c-28.5-15.3-59.1-23.4-93.7-23.4c-40.7,0-81.5,11.2-120.2,21.4S166,50,130.4,50c-23.8,0-45.3-4.6-65.2-13.7    V20.4C65.2,9.2,56,0,44.8,0S24.4,9.2,24.4,20.4v448.2c0,11.2,9.2,20.4,20.4,20.4s20.4-9.2,20.4-20.4v-148    c20,6.9,41.2,10.5,64.2,10.5c40.7,0,81.5-11.2,120.2-20.4c38.7-10.2,74.4-20.4,110-20.4c27.5,0,52,6.1,74.4,18.3    c12.7,8.7,30.6-2.1,30.6-17.3V49.9C464.4,41.8,460.4,35.7,454.3,31.6z M423.7,258.8c-20.4-7.1-41.8-10.2-64.2-10.2    c-40.7,0-81.5,11.2-120.2,21.4s-74.4,20.4-110,20.4c-23.4,0-44.8-4.1-64.2-13.2V79.5c20.4,7.1,41.8,10.2,64.2,10.2    c40.7,0,81.5-11.2,120.2-21.4s74.4-20.4,110-20.4c23.4,0,44.8,4.1,64.2,13.2V258.8z"/>
                            </g>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
                      &nbsp;
                    </div>
                    <div className="flex items-center justify-end space-x-5 pt-5">
                      <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                        <div className="flex rounded-lg border-transparent">
                          <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                            <Clock />
                          </span>
                          <input
                            id="price-price-ADAS Operations-Headlight Control Module-0"
                            disabled={manualLine.removed}
                            className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                            type="number"
                            value={manualLine.lbr_hrs ? manualLine.lbr_hrs : ""}
                            onChange={(e) => updateManualLine('lbr_hrs', index, e.target.value)}
                            onBlur={(e) => saveManualLine('lbr_hrs', index, e.target.value)}
                          />
                        </div>
                        <span className="text-xs">Labor Hours</span>
                      </div>
                      <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                        <div className="flex rounded-lg border-transparent">
                          <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                            <Cog />
                          </span>
                          <select
                            id="price-price-ADAS Operations-Headlight Control Module-0"
                            disabled={manualLine.removed}
                            className="bg-gray-50 w-28 p-2.5 pl-2 text-base leading-4 block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                            value={manualLine.lbr_type ? manualLine.lbr_type : ""}
                            onChange={(e) => updateManualLine('lbr_type', index, e.target.value)}
                            onBlur={(e) => saveManualLine('lbr_type', index, e.target.value)}
                          >
                            <option value=""></option>
                            <option value="Body">Body</option>
                            <option value="Mech">Mech</option>
                            <option value="Refinish">Refinish</option>
                          </select>
                        </div>
                        <span className="text-xs">Type</span>
                      </div>
                      <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                        <div className="flex rounded-lg border-transparent">
                          <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                            $
                          </span>
                          <input
                            id="price-price-ADAS Operations-Headlight Control Module-0" 
                            disabled={manualLine.removed}
                            className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                            type="number"
                            value={manualLine.price ? manualLine.price : 0}
                            onChange={(e) => updateManualLine('price', index, e.target.value)}
                            onBlur={(e) => saveManualLine('price', index, e.target.value)}
                          />
                        </div>
                        <span className="text-xs">Price</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}

      {isSuperAdmin && (
        <>
          <div id="body-and-frame" className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow dark:border-gray-700 bg-strapi-neutral-800">
            <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Repair Procedures
            </h4>
          </div>
          <div className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow dark:border-gray-700 bg-strapi-neutral-800">
            {urlkey === 'nb011ebz-ra7u-i2cy-1dwx-3jz8kvqats6s' ? (
              <table className="min-w-full border-collapse text-white">
                <thead>
                  <tr className="bg-gray-800 bg-opacity-50">
                    <th className="py-2 px-4 border-gray-700 text-left border-r border-solid">Component</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">Repair</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">P&L</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">TSB</th>
                    <th className="py-2 px-4 border-gray-700 text-center">SPEC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3400/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Air Bag(s) Arming and Disarming</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3400/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/163/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Bumper</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/163/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Doors, Hood and Trunk</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/783/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Fender</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/783/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table className="min-w-full border-collapse text-white">
                <thead>
                  <tr className="bg-gray-800 bg-opacity-50">
                    <th className="py-2 px-4 border-gray-700 text-left border-r border-solid">Component</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">Repair</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">P&L</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">TSB</th>
                    <th className="py-2 px-4 border-gray-700 text-center">SPEC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3400/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Air Bag(s) Arming and Disarming</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3400/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7364/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Apron / Front Fender Inner Panel</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7364/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3720/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Body Control Systems</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3720/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/8409/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Body Dimensions</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/8409/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/163/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Bumper</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/163/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7296/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Construction Materials</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7296/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7288/filter/noFilter#" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Corrosion Protection</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7288/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Cowl</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7232/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Cowl Moulding / Trim</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7232/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Doors, Hood and Trunk</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3821/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Exterior Moulding / Trim</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3821/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/783/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Fender</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/783/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3020/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Firewall</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3020/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Frame</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/136/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Cross-Member</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/136/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 pl-8  border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/787/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Frame Rail</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4  border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1326/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Subframe</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7203/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Information Labels</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7203/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1291/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Interior Moulding / Trim</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1298/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Locks</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1298/filter/noFilter#" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/754/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Mirrors</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/754/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/754/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7195/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Paint, Striping and Decals</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7195/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7192/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Pillars, Rockers and Floor</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7182/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Quarter Panel</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7182/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3873/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Radiator Support</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3873/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7178/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Rear Panel</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7178/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/6389/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Relays and Modules - Body and Frame</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/6978/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Repairs and Inspections Required After a Collision</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="#https://my.alldata.com/repair/#/vehicle/63682/component/6978/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7176/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Roof and Associated Components</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7176/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/170/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Seats</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/170/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/170/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/6401/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Sensors and Switches - Body and Frame</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Spoilers, Flaps, and Air Dams</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/165/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Spoiler</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/165/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Unibody</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/2455/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Cowl</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Weatherstrip</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7402/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Front Door Window Glass Weatherstrip</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7402/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7405/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Rear Door Window Glass Weatherstrip</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r text-center border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7405/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
          )}
          </div>
        </>
      )}

      {positionStatement && positionStatement.length > 0 && (
        <div id="position-statement" className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
          <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Position Statement
          </h4>
          <ul className="list-decimal pl-4 mt-4">
            {positionStatement.map((statement, index) => (
              <li key={index} className="text-gray-500 dark:text-gray-400">
                <a target="_blank" rel="noreferrer" href={statement.fileUrl} className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                  {statement.fileName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
        <div className="flex flex-row items-center justify-between">
          <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Total Labor Hours
          </h4>
          <div className="mr-2 flex justify-end text-lg font-semibold text-gray-200">
            {totalLaborHours} {totalLaborHours === 1 ? "hour" : "hours"}
          </div>
        </div>
        {Object.keys(totalLaborHoursSummary).map((key) => (
          <div key={key} className="flex flex-row items-center justify-between">
            <div>&nbsp;</div>
            <div className="flex flex-row items-center justify-between w-[400px]">
              <h4 className="max-w-full text-md text-gray-400">
                {key}
              </h4>
              <div className="mr-2 flex justify-end text-md font-medium text-gray-400">
                {roundToTwoDecimals(totalLaborHoursSummary[key])} {totalLaborHoursSummary[key] === 1 ? "hour" : "hours"}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
        <div className="flex flex-row items-center justify-between">
          <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Total Projected Price
          </h4>
          <div className="mr-2 flex justify-end text-lg font-semibold text-gray-200">
            ${totalProjectedPrice.toFixed(2)}
          </div>
        </div>
        {Object.keys(totalProjectedPriceSummary).map((key) => (
          <div key={key} className="flex flex-row items-center justify-between">
            <div>&nbsp;</div>
            <div className="flex flex-row items-center justify-between w-[400px]">
              <h4 className="max-w-full text-md text-gray-400">
                {key}
              </h4>
              <div className="mr-2 flex justify-end text-md font-medium text-gray-400">
                ${totalProjectedPriceSummary[key].toFixed(2)}
              </div>
            </div>
          </div>
        ))}
      </div>
      {addOperation && 
        <ModalLayout onClose={() => setAddOperation(false)}>
          <ModalHeader>
            <Typography fontWeight="bold" textColor="neutral800" as="h2" id="title">
              Add Operation
            </Typography>
          </ModalHeader>
          <ModalBody>
            <form id="operation-form" className="space-y-6">
              <SingleSelect
                label="Operation Type"
                value={addOperationFormData.operationType}
                onChange={(value) => handleAddOperationFormChange("operationType", value)}
                id="operation_ct_type" 
              >
                <SingleSelectOption value="adas_operation">ADAS Operation</SingleSelectOption>
                <SingleSelectOption value="safety_operation">Safety Operation</SingleSelectOption>
                <SingleSelectOption value="steering_operation">Steering Operation</SingleSelectOption>
                <SingleSelectOption value="guide_line">Guidelines</SingleSelectOption>
              </SingleSelect>
              {(addOperationFormData.operationType === "adas_operation" || addOperationFormData.operationType === "steering_operation") && (
                <>
                  <TextInput
                    label="Name"
                    name="name"
                    placeholder="Front Radar"
                    value={addOperationFormData.name}
                    onChange={(e) => handleAddOperationFormChange("name", e.target.value)}
                  />
                  <TextInput
                    label="Calibration Type"
                    name="procedureType"
                    placeholder="Static"
                    value={addOperationFormData.procedureType}
                    onChange={(e) => handleAddOperationFormChange("procedureType", e.target.value)}
                  />
                  <TextInput
                    label="Responsible For"
                    name="systems"
                    placeholder="Distance Sensor Alignment"
                    value={addOperationFormData.systems}
                    onChange={(e) => handleAddOperationFormChange("systems", e.target.value)}
                  />
                  <div id="triggers-add">
                    {addOperationFormData.triggers.map((trigger, index) => (
                      index === 0 ?
                      <Box marginBottom={2}>
                        <TextInput
                          key={index}
                          label="Trigger"
                          name="triggers[]"
                          placeholder="Front bumper is Replaced(line number 0)"
                          value={trigger}
                          onChange={(e) => handleTriggersChange(index, e.target.value)}
                        />
                      </Box>
                      :
                      <Box marginBottom={2}>
                        <TextInput
                          key={index}
                          aria-label="Trigger"
                          name="triggers[]"
                          value={trigger}
                          onChange={(e) => handleTriggersChange(index, e.target.value)}
                        />
                      </Box>
                    ))}
                    <Button
                      onClick={() => handleAddTrigger()}
                      variant="secondary"
                    >
                      Add More Trigger
                    </Button>
                  </div>
                  <TextInput
                    label="Documentation Link"
                    name="link"
                    placeholder="https://my.alldata.com/repair/#/vehicle/63682/component/165/filter/noFilter"
                    value={addOperationFormData.link}
                    onChange={(e) => handleAddOperationFormChange("link", e.target.value)}
                  />
                  <TextInput
                    label="Labor Hours"
                    name="labor_hours"
                    placeholder="1"
                    value={addOperationFormData.labor_hours}
                    onChange={(e) => handleAddOperationFormChange("labor_hours", e.target.value)}
                    type="text"
                  />
                  <SingleSelect
                    label="Labor Type"
                    name="labor_type"
                    value={addOperationFormData.labor_type}
                    onChange={(value) => handleAddOperationFormChange("labor_type", value)}
                  >
                    <SingleSelectOption value="">Select Labor Type</SingleSelectOption>
                    <SingleSelectOption value="Body">Body</SingleSelectOption>
                    <SingleSelectOption value="Mech">Mech</SingleSelectOption>
                    <SingleSelectOption value="Refinish">Refinish</SingleSelectOption>
                  </SingleSelect>
                  <TextInput
                    label="Price"
                    name="price"
                    placeholder="100"
                    value={addOperationFormData.price}
                    onChange={(e) => handleAddOperationFormChange("price", e.target.value)}
                    type="text"
                  />
                </>
              )}
              {addOperationFormData.operationType === "safety_operation" && (
                <>
                  <TextInput
                    label="Name"
                    name="name"
                    placeholder="Vehicle Diagnostics"
                    value={addOperationFormData.name}
                    onChange={(e) => handleAddOperationFormChange("name", e.target.value)}
                  />
                  <TextInput
                    label="Calibration Type"
                    name="procedureType"
                    placeholder="Static"
                    value={addOperationFormData.procedureType}
                    onChange={(e) => handleAddOperationFormChange("procedureType", e.target.value)}
                  />
                  <TextInput
                    label="Documentation Link"
                    name="link"
                    placeholder="https://my.alldata.com/repair/#/vehicle/63682/component/165/filter/noFilter"
                    value={addOperationFormData.link}
                    onChange={(e) => handleAddOperationFormChange("link", e.target.value)}
                  />
                  <TextInput
                    label="Labor Hours"
                    name="labor_hours"
                    placeholder="1"
                    value={addOperationFormData.labor_hours}
                    onChange={(e) => handleAddOperationFormChange("labor_hours", e.target.value)}
                    type="text"
                  />
                  <SingleSelect
                    label="Labor Type"
                    name="labor_type"
                    value={addOperationFormData.labor_type}
                    onChange={(value) => handleAddOperationFormChange("labor_type", value)}
                  >
                    <SingleSelectOption value="">Select Labor Type</SingleSelectOption>
                    <SingleSelectOption value="Body">Body</SingleSelectOption>
                    <SingleSelectOption value="Mech">Mech</SingleSelectOption>
                    <SingleSelectOption value="Refinish">Refinish</SingleSelectOption>
                  </SingleSelect>
                  <TextInput
                    label="Price"
                    name="price"
                    placeholder="100"
                    value={addOperationFormData.price}
                    onChange={(e) => handleAddOperationFormChange("price", e.target.value)}
                    type="text"
                  />
                </>
              )}
              {addOperationFormData.operationType === "guide_line" && (
                <>
                  <TextInput
                    label="Name"
                    name="name"
                    placeholder="Repair Shipping Damage"
                    value={addOperationFormData.name}
                    onChange={(e) => handleAddOperationFormChange("name", e.target.value)}
                  />
                  <TextInput
                    label="Labor Hours"
                    name="labor_hours"
                    placeholder="1"
                    value={addOperationFormData.labor_hours}
                    onChange={(e) => handleAddOperationFormChange("labor_hours", e.target.value)}
                    type="text"
                  />
                  <SingleSelect
                    label="Labor Type"
                    name="labor_type"
                    value={addOperationFormData.labor_type}
                    onChange={(value) => handleAddOperationFormChange("labor_type", value)}
                  >
                    <SingleSelectOption value="Body">Body</SingleSelectOption>
                    <SingleSelectOption value="Mech">Mech</SingleSelectOption>
                    <SingleSelectOption value="Refinish">Refinish</SingleSelectOption>
                  </SingleSelect>
                  <TextInput
                    label="Price"
                    name="price"
                    placeholder="100"
                    value={addOperationFormData.price}
                    onChange={(e) => handleAddOperationFormChange("price", e.target.value)}
                    type="text"
                  />
                </>
              )}
            </form>
          </ModalBody>
          <ModalFooter endActions={<Button onClick={handleAddOperationFormSubmit}>Submit</Button>}>
          </ModalFooter>
        </ModalLayout>
      }
      {shareReport && (
        <div>
          <div
            className="relative z-10"
            id="headlessui-dialog-:r2t:"
            role="dialog"
            aria-modal="true"
            data-headlessui-state="open"
            aria-labelledby="headlessui-dialog-title-:r2v:"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                  className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                  id="headlessui-dialog-panel-:r2u:"
                  data-headlessui-state="open"
                >
                  <div className="bg-white p-4">
                    <div className="flex w-full sm:flex sm:items-start">
                      <div className="w-full">
                        <h1
                          className="inline-flex w-full items-center justify-between border-b-1 pb-2 text-base font-semibold leading-6 text-gray-900"
                          id="headlessui-dialog-title-:r2v:"
                          data-headlessui-state="open"
                        >
                          <span>Share Report</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                            data-slot="icon"
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => setShareReport(false)}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>
                        </h1>
                        <div className="w-full mt-3">
                          <form className="space-y-6">
                            <div className="flex-col">
                              <label
                                htmlFor="sensor"
                                className="mb-1 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-900"
                              >
                                Emails
                              </label>
                              <textarea
                                id="emails_share"
                                name="emails"
                                className="mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                placeholder="Emails"
                                required=""
                                rows="3"
                              ></textarea>
                              <div id="share-report-error" className="text-red-500 text-sm mt-2 hidden">
                                Some thing went wrong. Please try again.
                              </div>
                              <p className="dark:text-gray-500 text-sm mt-2">Ex: email1@example.com, email2@example.com</p>
                            </div>
                          </form>
                        </div>
                        <div className="mt-3 w-full">
                          <Button
                            onClick={shareEmailReport}
                            loading={isSendingEmail}
                            startIcon={<Envelop />}
                          >
                            Share Report
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Adas;
