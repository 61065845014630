import { Switch, Route } from "react-router-dom";
import React, { useState } from "react";
import AccountSettingsMitchell from "./AccountSettingsMitchell";
import AccountSettingsIntergrations from "./Account/AccountSettingsIntergrations";
import { Helmet } from "react-helmet";
import Header from "../components/Header";

export default function Settings() {
  const menuActive = 'intergrations';
  const [leftNavVisible, setLeftNavVisible] = useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim App - Settings</title>
      </Helmet>
      <Header 
        leftNavVisible={leftNavVisible} 
        setLeftNavVisible={setLeftNavVisible} 
        menuActive={menuActive}
      />
      <main className={`${leftNavVisible? 'ml-64' : ''} transition-all flex-1 pt-[73px]`}>
        <Switch>
          <Route
            path="/intergrations/config-mitchell"
            render={() => <AccountSettingsMitchell />}
          />
          <Route
            path="/intergrations/config-ccc"
            render={() => <AccountSettingsIntergrations typeAccount="ccc" />}
          />
        </Switch>
      </main>
    </>
  );
}
