import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalContextState } from "../../context/globalContext";
import Toolbar from '../Toolbar/Toolbar';
import { baseUrl } from "../../config";
import DatePicker from 'react-datepicker';
import { Helmet } from "react-helmet";
import 'react-datepicker/dist/react-datepicker.css';
import Tooltip from '@mui/material/Tooltip';
import { ExternalLink } from "@strapi/icons";
import socket from '../../socketio';
import { useLocation } from 'react-router-dom';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const getClaimByUser = `${baseUrl}/api/claim/get-user-claims`;

export default function ClaimsList() {
  const query = useQuery();
  const token  = useContext(GlobalContextState).token;
  const user = useContext(GlobalContextState).user;
  const location = useLocation();
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [totalClaims, setTotalClaims] = useState(0);
  const [claimsListDataRb, setClaimsListDataRb] = useState([]);
  const [sortTcType, setSortTcType] = useState('');
  const [sortHumanType, setSortHumanType] = useState('');

  const [estDone, setEstDone] = useState(query.get('est') === 'true')
  const [revDone, setRevDone] = useState(query.get('rev') === 'true');
  const [adasDone, setAdasDone] = useState(query.get('adas') === 'true');
  const [chatDone, setChatDone] = useState(query.get('chat') === 'true');
  const [filterDate, setFilterDate] = useState(query.get('filterDate') ? new Date(query.get('filterDate')) : '');
  const [searchUser, setSearchUser] = useState(query.get('searchUser') ? query.get('searchUser') : 'nos');
  
  const [copiedString, setCopiedString] = useState(null);
  const tableRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  
  const [tooltipText, setTooltipText] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showUserFilter, setShowUserFilter] = useState(false);

  const listClaimsListening = useRef([]);
  const listClaimsData = useRef([]);

  const handleDateChange = (date) => {
    setFilterDate(date);
    setTooltipText(date ? date.toLocaleDateString() : null);
    setShowDatePicker(false);

    const newDate = new Date(date);
    const formattedDate = newDate.toISOString().split('T')[0];
    updateUrlParams('filterDate', formattedDate);
  };

  const resetFilterDate = () => {
    setFilterDate('');
    fetchClaims(estDone, revDone, adasDone, chatDone, false);
    setTooltipText(null);
    setShowDatePicker(false);
    updateUrlParams('filterDate', '');
  };

  useEffect(() => {
    const initFetchData = async () => {
      const searchParams = new URLSearchParams(location.search);
      await fetchClaims(searchParams);

      if(user.superAdmin){
        const url = `${baseUrl}/api/users`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setAllUsers(data);
      }
    };

    initFetchData();
    
    const userId = user.id;
    socket.on(`claim-process-status-updated-${userId}`, (data) => {
      if(listClaimsListening.current.includes(data.tcUrlKey)){
        const updatedData = listClaimsData.current.map((claim) => {
          if(claim.urlkey === data.tcUrlKey){
            claim.estStatus = data.estStatus;
            claim.revStatus = data.revStatus;
            claim.adasStatus = data.adasStatus;
          }
          return claim;
        });

        setClaimsListDataRb(updatedData);
        listClaimsData.current = updatedData;
      }
    });

    socket.on(`claim-message-status-updated-${userId}`, (data) => {
      if(listClaimsListening.current.includes(data.tcUrlKey)){
        const updatedData = listClaimsData.current.map((claim) => {
          if(claim.urlkey === data.tcUrlKey){
            claim.messClass = 'owner-msg-sent'
          }
          return claim;
        });

        setClaimsListDataRb(updatedData);
        listClaimsData.current = updatedData;
      }
    });

    socket.on(`chat-message-sended-${userId}`, (data) => {
      console.log('chat-message-sended', data);
      if(listClaimsListening.current.includes(data.tcUrlKey)){
        const updatedData = listClaimsData.current.map((claim) => {
          if(claim.urlkey === data.tcUrlKey){
            claim.messClass = data.messClass
            claim.messStep = data.doneStep;
          }
          return claim;
        });

        setClaimsListDataRb(updatedData);
        listClaimsData.current = updatedData;
      }
    });

    socket.on(`new-claim-created-${userId}`, (data) => {
      const searchParams = new URLSearchParams(window.location.search);
      fetchClaims(searchParams);
    });
    
    return () => {
      socket.off(`claim-process-status-updated-${userId}`);
      socket.off(`claim-message-status-updated-${userId}`);
      socket.off(`chat-message-sended-${userId}`);
      socket.off(`new-claim-created-${userId}`);
    }
  }, []);

  useEffect(() => {
    const initFetchData = async () => {
      const searchParams = new URLSearchParams(location.search);
      await fetchClaims(searchParams);
    };

    initFetchData();
  }, [location.search]);

  const updatePageSize = (pSize) => {
    setPageSize(pSize);
  }

  const updateActivePage = (pActive) => {
    setActivePage(pActive);
  }

  const fetchEstDoneClaims = async () => {
    const newStatus = !estDone;
    setEstDone(newStatus);
    updateUrlParams('est', newStatus);
  }

  const fetchRevDoneClaims = async () => {
    const newStatus = !revDone;
    setRevDone(newStatus);
    updateUrlParams('rev', newStatus);
  }

  const fetchAdasDoneClaims = async () => {
    const newStatus = !adasDone;
    setAdasDone(newStatus);
    updateUrlParams('adas', newStatus);
  }

  const fetchChatDoneClaims = async () => {
    const newStatus = !chatDone;
    setChatDone(newStatus);
    updateUrlParams('chat', newStatus);
  }

  const updateUrlParams = (key, value) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('page');
    if(value === '' || value === false || value === 'false' || value === 0){
      searchParams.delete(key);
    }else {
      searchParams.set(key, value);
    }
    window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    fetchClaims(searchParams);
  }

  async function fetchClaims(searchParams) {
    try {
      // Get all params from the URL
      const currentPage = parseInt(searchParams.get('page')) || 1;
      const currentPageSize = parseInt(searchParams.get('pageSize')) || 30;

      setLoading(true);
      setClaimsListDataRb([]);
      listClaimsData.current = [];
      
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      let fetchUrl = `${getClaimByUser}?pageSize=${currentPageSize}&page=${currentPage}`;
      if(searchParams.get('search') && searchParams.get('search').length > 3){
        fetchUrl += `&search=${searchParams.get('search')}`;
      }

      if(searchParams.get('searchUser') && searchParams.get('searchUser') !== 'nos'){
        fetchUrl += `&searchUser=${searchParams.get('searchUser')}`;
      }

      if(searchParams.get('filterDate')){
        fetchUrl += `&filterDate=${searchParams.get('filterDate')}`;
      }

      if(searchParams.get('est') === 'true'){ fetchUrl += `&estDone=1`; }
      if(searchParams.get('rev') === 'true'){ fetchUrl += `&revDone=1`; }
      if(searchParams.get('adas') === 'true'){ fetchUrl += `&adasDone=1`; }
      if(searchParams.get('chat') === 'true'){ fetchUrl += `&chatDone=1`; }

      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        if(data){
          generateEstimateData(data.data.attributes.results);
          setTotalClaims(data.data.attributes.pagination.total);
          setPageCount(data.data.attributes.pagination.pageCount);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const getChatMessagesCount = async (messsages) => {
    let doneSteps = [];
    if(messsages.length === 0){
      return doneSteps;
    }

    await messsages.forEach( (message) => {
      if (message.action === "upload-image" && message.type === "image") {
        doneSteps.push('upload-image');
      }
      if (message.action === "confirm-airbags") {
        doneSteps.push('confirm-airbags');
      }
      if (message.action === "confirm-steering") {
        doneSteps.push('confirm-steering');
      }
      if (message.action === "confirm-mileage") {
        doneSteps.push('confirm-mileage');
      }
      if (message.action === "confirm-vin") {
        doneSteps.push('confirm-vin');
      }
      if (message.action === "confirm-pois") {
        doneSteps.push('confirm-pois');
      }
      if (message.action === "confirm-parts") {
        doneSteps.push('confirm-parts');
      }
      if (message.action === "session-end") {
        doneSteps.push('session-end');
      }
    });

    // Remove duplicates
    doneSteps = doneSteps.filter((item, index) => doneSteps.indexOf(item) === index);

    return doneSteps;
  }

  const capitalizeStr = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const generateEstimateData = async (claimData) => {
    try {
      let listClaimsListeningPrepared = [];
      let listClaimNotSentMsgPrepared = [];
      const claimsListData = [];
      const mitchellFormData = [];
      
      claimData.forEach( async (claim) => {
        let messClass = '';
        let onlyAdas = true;
        let totalSteps = 0;
        listClaimsListeningPrepared.push(claim.tcUrlKey);
        if(claim.mitchellUrlKey){
          onlyAdas = false;
          const mitchellData = {
            mitchellUrlKey: claim.mitchellUrlKey,
            mitchellId: claim.user.mitchellId
          }
          mitchellFormData.push(mitchellData);
        }

        
        if(claim.ownerMsgSent){
          messClass = 'owner-msg-sent';
        }else {
          listClaimNotSentMsgPrepared.push(claim.tcUrlKey);
        }

        const checkSteps = ['upload-image', 'confirm-airbags', 'confirm-steering', 'confirm-mileage', 'confirm-vin', 'confirm-pois', 'confirm-parts', 'session-end'];
        
        const messages = claim.chat_messages;
        const doneSteps = await getChatMessagesCount(messages);
        
        // If checkSteps and doneSteps are the same, then the chat is complete
        totalSteps = doneSteps.length;
        if(doneSteps.length > 0){
          if(doneSteps.length === checkSteps.length){
            messClass = 'owner-msg-completed';
          }else {
            messClass = 'owner-msg-sent-but-not-complete';
          }
        }

        const claimColumnData = {
          id: claim.id,
          onlyAdas: onlyAdas,
          urlkey: claim.tcUrlKey,
          mitchellUrlKey: claim.mitchellUrlKey,
          mitchelUrl: claim.user.mitchellUrl ? claim.user.mitchellUrl : '',
          estimatic: claim.estimatic,
          vehicleVin: claim.vehicleVin,
          vehicle: claim.vehicleName,
          tcEstimate: 0,
          humanEstimate: 0,
          messComplete: 0,
          messClass: messClass,
          messStep: totalSteps,
          createdAt: new Date(claim.createdAt).toLocaleString('en-US', { timeZone: 'America/New_York', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }),
          user: claim.user.company ? claim.user.company : claim.user.username,
          estStatus: claim.estStatus,
          revStatus: claim.revStatus,
          trailStatusCheck: '',
          trailStatusClass: '',
          claimEstimated: '',
          estimateApproved: false,
          adasStatus: claim.adasStatus
        }
        claimsListData.push(claimColumnData);
      });
      
      listClaimsListening.current = listClaimsListeningPrepared;

      try {
        const url = `${baseUrl}/api/tc-app/get-bms-data`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ data: mitchellFormData }),
        });

        const data = await response.json();
        data.forEach( (claim) => {
          // If the claim is not found in the data, then skip it
          if(!claim){
            return;
          }

          claimsListData.forEach( async (claimlData) => {
            if(claimlData.mitchellUrlKey === claim.urlkey){
              claimlData.tcEstimate = claim.dataTcBms;
              claimlData.trailStatusCheck = claim.trailStatusCheck;
              claimlData.trailStatusClass = claim.trailStatusClass;
              claimlData.claimEstimated = claim.claimEstimated;
              if(claim.dataHumanBms !== 0){
                claimlData.estimateApproved = true;
              }
              if(claim.dataHumanBms === 0 && claim.claimEstimated !== ''){
                claimlData.humanEstimate = claim.claimEstimated ? claim.claimEstimated.replace('$','') : 0;
              }else {
                claimlData.humanEstimate = claim.dataHumanBms ? claim.dataHumanBms.replace('$','') : 0; 
              }
            }
          });
        });
      } catch (error) {
        console.error(error);
      }

      claimsListData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setClaimsListDataRb(claimsListData);
      listClaimsData.current = claimsListData;

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const processStatusMap = {
    pending: "pending",
    created: "pending",
    processing: "processing",
    mitchell_estimating: "processing",
    completed: "completed",
    failed: "failed",
  };

  const sortByTcEstimate = () => {
    setSortHumanType('');
    if(sortTcType === 'desc'){
      const sortedData = claimsListDataRb.sort((a, b) => a.tcEstimate - b.tcEstimate);
      setClaimsListDataRb([...sortedData]);
      listClaimsData.current = sortedData;

      setSortTcType('asc');
    }else{
      const sortedData = claimsListDataRb.sort((a, b) => b.tcEstimate - a.tcEstimate);
      setClaimsListDataRb([...sortedData]);
      listClaimsData.current = sortedData;

      setSortTcType('desc');
    }
  };
  
  const sortByHumanEstimate = () => {
    setSortTcType('');
    if(sortHumanType === 'desc'){
      const sortedData = claimsListDataRb.sort((a, b) => a.humanEstimate - b.humanEstimate);
      setClaimsListDataRb([...sortedData]);
      listClaimsData.current = sortedData;

      setSortHumanType('asc');
    }else{
      const sortedData = claimsListDataRb.sort((a, b) => b.humanEstimate - a.humanEstimate);
      setClaimsListDataRb([...sortedData]);
      listClaimsData.current = sortedData;

      setSortHumanType('desc');
    }
  };

  const handleCopy = (string) => {
    navigator.clipboard.writeText(string).then(() => {
      setCopiedString(string);
      
      setTimeout(() => {
        setCopiedString(null);
      }, 2000);
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const tablePosition = tableRef.current.getBoundingClientRect().top;
        const tablePostLeft = tableRef.current.getBoundingClientRect().left;
        const tablePosRight = tableRef.current.getBoundingClientRect().right;
        
        const tableHeader = document.querySelector('.table-list-header');
        tableHeader.style.left = `${tablePostLeft}px`;
        const bodyWidth = document.body.clientWidth;
        tableHeader.style.right = `${bodyWidth - tablePosRight}px`;
        document.querySelectorAll('.table-sticky-header tr th').forEach((th, index) => {
          const width = th.offsetWidth;  // Get the current width of the <th>
          
          // Set the width for the <th>
          th.style.width = `${width}px`;
        
          // Find all <td> in the same column (matching the same index)
          document.querySelectorAll(`.table-sticky-header tr td:nth-child(${index + 1})`).forEach(td => {
            // Set the same width for the <td>
            td.style.width = `${width}px`;
          });
        });
        // Add the sticky class if the table starts to overlap the header area
        if (tablePosition <= 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getChatTooltip = (claim) => {
    if(claim.messStep > 0){
      return `(${claim.messStep}/8)`;
    }
    if(claim.messClass === 'owner-msg-sent'){
      return "SMS sent";
    }
    return "-";
  }

  const changeSearchUser = (value) => {
    if(value === 'nos'){
      updateUrlParams('searchUser', '');
      setSearchUser('');
      setShowUserFilter(false);
      return;
    }

    setSearchUser(value);
    setShowUserFilter(false);
    updateUrlParams('searchUser', value);
  }

  const goToChat = (tcUrlKey) => {
    window.open(`https://chat.trueclaim.ai/?claimId=${tcUrlKey}`, '_blank');
  }

  const goToClaimDetails = (tcUrlKey, tab) => {
    window.open(`/claims/claim-details/${tcUrlKey}?tab=${tab}`);
  }
  
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim App - Claims</title>
      </Helmet>
      <div className="px-14 flex justify-between items-center pt-8">
        <div>
          <h2 className="text-2xl font-bold text-white text-3xl">Claims</h2>
          <p className="text-sm text-gray-500">{totalClaims} entries found</p>
        </div>
      </div>
      <div className="px-14 py-4">
        <div ref={tableRef} 
          className={`table-sticky-header bg-gray-800 rounded ${isSticky ? 'sticky-header' : ''}`}>
          <table className="w-full table-list">
            <thead className={`table-list-header ${isSticky ? 'px-2 fixed z-50 bg-gray-700 top-[73px]' : ''}`}>
              <tr>
                <th className="p-4 whitespace-nowrap text-left text-xs font-semibold border-r border-solid border-neutral-700">
                  VEHICLE
                </th>
                <th className="w-[1%] text-xs font-semibold p-4 border-r border-solid border-neutral-700">
                  <div className="flex items-center">
                    <Tooltip title="Chat Status" arrow>
                      CHAT
                    </Tooltip>
                    <input type="checkbox" value={chatDone}
                      disabled={loading}
                      checked={chatDone}
                      onChange={() => fetchChatDoneClaims()}
                      className="w-4 h-4 ml-2 cursor-pointer text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 bg-gray-700 dark:border-gray-600 checked:bg-green-500 " />
                  </div>
                </th>
                <th className="w-16 text-xs font-semibold p-4 border-r border-solid border-neutral-700">
                  <div className="flex items-center">
                    <Tooltip title="EST Status" arrow>
                      EST
                    </Tooltip>
                    <input type="checkbox" value={estDone}
                      disabled={loading}
                      checked={estDone}
                      onChange={() => fetchEstDoneClaims()}
                      className="w-4 h-4 ml-2 cursor-pointer text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 bg-gray-700 dark:border-gray-600 checked:bg-green-500 " />
                  </div>
                </th>
                <th className="w-16 text-xs font-semibold p-4 border-r border-solid border-neutral-700">
                  <div className="flex items-center">
                    <Tooltip title="REV Status" arrow>
                      REV
                    </Tooltip>
                    <input type="checkbox" value={revDone}
                      disabled={loading}
                      checked={revDone}
                      onChange={() => fetchRevDoneClaims()}
                      className="w-4 h-4 ml-2 cursor-pointer text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 bg-gray-700 dark:border-gray-600 checked:bg-green-500 " />
                  </div>
                </th>
                <th className="w-16 text-xs font-semibold p-4 border-r border-solid border-neutral-700">
                  <div className="flex items-center">
                    <Tooltip title="ADAS Status" arrow>
                      ADAS
                    </Tooltip>
                    <input type="checkbox" value={adasDone}
                      disabled={loading}
                      checked={adasDone}
                      onChange={() => fetchAdasDoneClaims()}
                      className="w-4 h-4 ml-2 cursor-pointer text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 bg-gray-700 dark:border-gray-600 checked:bg-green-500 " />
                  </div>
                </th>
                <th className="p-4 whitespace-nowrap text-left text-xs font-semibold border-r border-solid border-neutral-700 w-[1%]">
                  ESTIMATICS
                </th>
                <th className="whitespace-nowrap text-left text-xs font-semibold p-4 border-r border-solid border-neutral-700">
                  VIN
                </th>
                <th className="whitespace-nowrap cursor-n-resize w-[1%] text-xs font-semibold p-4 border-r border-solid border-neutral-700" onClick={() => sortByTcEstimate()}>
                  TC EST
                </th>
                <th className="whitespace-nowrap cursor-n-resize w-[1%] text-xs font-semibold p-4 border-r border-solid border-neutral-700" onClick={() => sortByHumanEstimate()}>
                  HUMAN EST
                </th>
                {user.superAdmin && (
                  <th className="cursor-n-resize hidden-mb text-left text-xs font-semibold p-4 w-[1%] border-r border-solid border-neutral-700">
                    <div className="relative">
                      <span
                        className="cursor-pointer flex items-center"
                        onClick={() => setShowUserFilter(!showUserFilter)}
                      >
                        GARAGE
                        <svg className={`w-4 h-4 ml-10 ${searchUser && searchUser !== 'nos' ? 'text-green-500' : 'text-white'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M5.05 3C3.291 3 2.352 5.024 3.51 6.317l5.422 6.059v4.874c0 .472.227.917.613 1.2l3.069 2.25c1.01.742 2.454.036 2.454-1.2v-7.124l5.422-6.059C21.647 5.024 20.708 3 18.95 3H5.05Z"/>
                        </svg>
                      </span>

                    {showUserFilter && (
                      <div className="absolute top-[100%] left-[50%] transform -translate-x-1/2 mt-2 bg-gray-800 p-2 rounded w-72">
                        <select 
                          className="w-full bg-gray-800 text-white rounded p-2"
                          value={searchUser} onChange={e => changeSearchUser(e.target.value)}>
                          <option value="nos">Select User</option>
                          {allUsers && allUsers.map((userFilter) => (
                            !userFilter.superAdmin && (
                              <option key={userFilter.id} value={userFilter.username}>{`${userFilter.username} - ${userFilter.company}`}</option>
                            )
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  </th>
                )}
                <th className="whitespace-nowrap w-[1%] text-xs font-semibold p-4">
                  <div className="relative">
                    <Tooltip title={tooltipText || "Select a date"} placement="top" arrow>
                      <span
                        className="cursor-pointer flex items-center"
                        onClick={() => setShowDatePicker(!showDatePicker)}
                      >
                        CREATED AT
                        <svg className={`w-4 h-4 ml-1 ${filterDate ? 'text-green-500' : 'text-white'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M5.05 3C3.291 3 2.352 5.024 3.51 6.317l5.422 6.059v4.874c0 .472.227.917.613 1.2l3.069 2.25c1.01.742 2.454.036 2.454-1.2v-7.124l5.422-6.059C21.647 5.024 20.708 3 18.95 3H5.05Z"/>
                        </svg>
                      </span>
                    </Tooltip>

                    {showDatePicker && (
                      <div className="absolute top-[100%] left-[50%] transform -translate-x-1/2 mt-2 bg-gray-800 p-2 rounded">
                      <DatePicker
                        selected={filterDate}
                        onChange={handleDateChange}
                        isClearable={true}
                        inline
                        className="hidden"
                      >
                        {filterDate && (
                          <div onClick={() => resetFilterDate()} className="clear-left bottom-0 left-0 w-full p-2 rounded text-white bg-blue-700 font-medium cursor-pointer hover:bg-blue-600">
                            Clear date
                          </div>
                        )}
                      </DatePicker>
                      </div>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
          {loading ? (
            <tbody className="border-b border-solid border-neutral-700">
              {Array.from(Array(10).keys()).map((index) => (
                <tr className="animate-pulse border-t border-solid border-neutral-700"> 
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="h-4 bg-gray-200 rounded dark:bg-gray-700 w-full max-w-72"></div></td>
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="mx-auto h-4 w-4 bg-gray-200 rounded-full dark:bg-gray-700"></div></td>
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="mx-auto h-4 w-4 bg-gray-200 rounded-full dark:bg-gray-700"></div></td>
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="mx-auto h-4 w-4 bg-gray-200 rounded-full dark:bg-gray-700"></div></td>
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="mx-auto h-4 w-4 bg-gray-200 rounded-full dark:bg-gray-700 "></div></td>
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="h-4 bg-gray-200 rounded dark:bg-gray-700 w-full max-w-24"></div></td>
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="h-4 bg-gray-200 rounded dark:bg-gray-700 w-full max-w-24"></div></td>
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="h-4 bg-gray-200 rounded dark:bg-gray-700 w-full"></div></td>
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="h-4 bg-gray-200 rounded dark:bg-gray-700 w-full"></div></td>
                  {user.superAdmin && (
                    <td className="px-4 py-2.5 border-r border-solid border-neutral-700"><div className="h-4 bg-gray-200 rounded dark:bg-gray-700 w-full"></div></td>
                  )}
                  <td className="px-4 py-2.5"><div className="h-4 bg-gray-200 rounded dark:bg-gray-700 w-full max-w-28"></div></td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody className="border-b border-solid border-neutral-700">
              {claimsListDataRb.map((claim, index) => (
                <tr className="border-t border-solid border-neutral-700 hover:bg-gray-700" key={claim.id}> 
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700">
                    <a className="list-view-detail" href={`/claims/claim-details/${claim.urlkey}`}>
                      <Tooltip title={claim.vehicle && claim.vehicle !== '0 null null' ? claim.vehicle : 'No Vehicle Selected'} arrow>
                        <span className="max-150">{claim.vehicle && claim.vehicle !== '0 null null' ? claim.vehicle : 'No Vehicle Selected'}</span>
                      </Tooltip>
                    </a>
                  </td>

                  <td 
                  onClick={() => { goToChat(claim.urlkey) }}
                  className="px-4 py-2.5 border-r border-solid border-neutral-700 cursor-pointer">
                    <Tooltip title={getChatTooltip(claim)} arrow>
                      {claim.messClass === 'owner-msg-completed' && (
                        <svg className={`w-5 h-5 m-auto text-green-500`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z" clipRule="evenodd"/>
                        </svg>
                      )}
                      {claim.messClass === 'owner-msg-sent-but-not-complete' && (
                        <svg className="w-5 h-5 m-auto text-teal-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clipRule="evenodd"/>
                        </svg>
                      )}
                      {claim.messClass === 'owner-msg-sent' && (
                        <svg className="w-5 h-5 m-auto text-amber-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clipRule="evenodd"/>
                        </svg>
                      )}
                      {claim.messClass === '' && (
                        <svg className="w-5 h-5 m-auto text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clipRule="evenodd"/>
                        </svg>
                      )}
                    </Tooltip>
                  </td>
                  <td 
                  onClick={() => { goToClaimDetails(claim.urlkey, 'est') }}
                  className={`px-4 py-2.5 border-r border-solid border-neutral-700 ${processStatusMap[claim.estStatus] !== 'pending' ? 'cursor-pointer' : ''}`}>
                    <Tooltip title={claim.estStatus ? capitalizeStr(claim.estStatus) : "Pending"} arrow>
                      {processStatusMap[claim.estStatus] === 'completed' && (
                        <svg className={`w-5 h-5 m-auto text-green-500`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z" clipRule="evenodd"/>
                        </svg>
                      )}
                      {processStatusMap[claim.estStatus] === 'processing' && (
                        <svg className="w-5 h-5 m-auto text-teal-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clipRule="evenodd"/>
                        </svg>
                      )}
                      {processStatusMap[claim.estStatus] === 'failed' && (
                        <svg className="w-5 h-5 m-auto text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M18 17h-.09c.058-.33.088-.665.09-1v-1h1a1 1 0 0 0 0-2h-1.09a5.97 5.97 0 0 0-.26-1H17a2 2 0 0 0 2-2V8a1 1 0 1 0-2 0v2h-.54a6.239 6.239 0 0 0-.46-.46V8a3.963 3.963 0 0 0-.986-2.6l.693-.693A1 1 0 0 0 16 4V3a1 1 0 1 0-2 0v.586l-.661.661a3.753 3.753 0 0 0-2.678 0L10 3.586V3a1 1 0 1 0-2 0v1a1 1 0 0 0 .293.707l.693.693A3.963 3.963 0 0 0 8 8v1.54a6.239 6.239 0 0 0-.46.46H7V8a1 1 0 0 0-2 0v2a2 2 0 0 0 2 2h-.65a5.97 5.97 0 0 0-.26 1H5a1 1 0 0 0 0 2h1v1a6 6 0 0 0 .09 1H6a2 2 0 0 0-2 2v2a1 1 0 1 0 2 0v-2h.812A6.012 6.012 0 0 0 11 21.907V12a1 1 0 0 1 2 0v9.907A6.011 6.011 0 0 0 17.188 19H18v2a1 1 0 0 0 2 0v-2a2 2 0 0 0-2-2Zm-4-8.65a5.922 5.922 0 0 0-.941-.251l-.111-.017a5.52 5.52 0 0 0-1.9 0l-.111.017A5.925 5.925 0 0 0 10 8.35V8a2 2 0 1 1 4 0v.35Z"/>
                        </svg>
                      )}
                      {processStatusMap[claim.estStatus] === 'pending' && (
                        <svg className="w-5 h-5 m-auto text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clipRule="evenodd"/>
                        </svg>
                      )}
                    </Tooltip>
                  </td>
                  <td 
                  onClick={() => { goToClaimDetails(claim.urlkey, 'post') }}
                  className={`px-4 py-2.5 border-r border-solid border-neutral-700 ${processStatusMap[claim.revStatus] !== 'pending' ? 'cursor-pointer' : ''}`}>
                    <Tooltip title={claim.revStatus ? capitalizeStr(claim.revStatus) : "Pending"} arrow>
                      {processStatusMap[claim.revStatus] === 'completed' && (
                        <svg className={`w-5 h-5 m-auto text-green-500`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z" clipRule="evenodd"/>
                        </svg>
                      )}
                      {processStatusMap[claim.revStatus] === 'processing' && (
                        <svg className="w-5 h-5 m-auto text-teal-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clipRule="evenodd"/>
                        </svg>
                      )}
                      {processStatusMap[claim.revStatus] === 'failed' && (
                        <svg className="w-5 h-5 m-auto text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M18 17h-.09c.058-.33.088-.665.09-1v-1h1a1 1 0 0 0 0-2h-1.09a5.97 5.97 0 0 0-.26-1H17a2 2 0 0 0 2-2V8a1 1 0 1 0-2 0v2h-.54a6.239 6.239 0 0 0-.46-.46V8a3.963 3.963 0 0 0-.986-2.6l.693-.693A1 1 0 0 0 16 4V3a1 1 0 1 0-2 0v.586l-.661.661a3.753 3.753 0 0 0-2.678 0L10 3.586V3a1 1 0 1 0-2 0v1a1 1 0 0 0 .293.707l.693.693A3.963 3.963 0 0 0 8 8v1.54a6.239 6.239 0 0 0-.46.46H7V8a1 1 0 0 0-2 0v2a2 2 0 0 0 2 2h-.65a5.97 5.97 0 0 0-.26 1H5a1 1 0 0 0 0 2h1v1a6 6 0 0 0 .09 1H6a2 2 0 0 0-2 2v2a1 1 0 1 0 2 0v-2h.812A6.012 6.012 0 0 0 11 21.907V12a1 1 0 0 1 2 0v9.907A6.011 6.011 0 0 0 17.188 19H18v2a1 1 0 0 0 2 0v-2a2 2 0 0 0-2-2Zm-4-8.65a5.922 5.922 0 0 0-.941-.251l-.111-.017a5.52 5.52 0 0 0-1.9 0l-.111.017A5.925 5.925 0 0 0 10 8.35V8a2 2 0 1 1 4 0v.35Z"/>
                        </svg>
                      )}
                      {processStatusMap[claim.revStatus] === 'pending' && (
                        <svg className="w-5 h-5 m-auto text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clipRule="evenodd"/>
                        </svg>
                      )}
                    </Tooltip>
                  </td>
                  <td 
                  onClick={() => { goToClaimDetails(claim.urlkey, 'post') }}
                  className={`px-4 py-2.5 border-r border-solid border-neutral-700 ${processStatusMap[claim.adasStatus] !== 'pending' ? 'cursor-pointer' : ''}`}>
                    <Tooltip title={claim.adasStatus ? capitalizeStr(claim.adasStatus) : "Pending"} arrow>
                      {processStatusMap[claim.adasStatus] === 'completed' && (
                        <svg className={`w-5 h-5 m-auto text-green-500`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z" clipRule="evenodd"/>
                        </svg>
                      )}
                      {processStatusMap[claim.adasStatus] === 'processing' && (
                        <svg className="w-5 h-5 m-auto text-teal-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clipRule="evenodd"/>
                        </svg>
                      )}
                      {processStatusMap[claim.adasStatus] === 'failed' && (
                        <svg className="w-5 h-5 m-auto text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M18 17h-.09c.058-.33.088-.665.09-1v-1h1a1 1 0 0 0 0-2h-1.09a5.97 5.97 0 0 0-.26-1H17a2 2 0 0 0 2-2V8a1 1 0 1 0-2 0v2h-.54a6.239 6.239 0 0 0-.46-.46V8a3.963 3.963 0 0 0-.986-2.6l.693-.693A1 1 0 0 0 16 4V3a1 1 0 1 0-2 0v.586l-.661.661a3.753 3.753 0 0 0-2.678 0L10 3.586V3a1 1 0 1 0-2 0v1a1 1 0 0 0 .293.707l.693.693A3.963 3.963 0 0 0 8 8v1.54a6.239 6.239 0 0 0-.46.46H7V8a1 1 0 0 0-2 0v2a2 2 0 0 0 2 2h-.65a5.97 5.97 0 0 0-.26 1H5a1 1 0 0 0 0 2h1v1a6 6 0 0 0 .09 1H6a2 2 0 0 0-2 2v2a1 1 0 1 0 2 0v-2h.812A6.012 6.012 0 0 0 11 21.907V12a1 1 0 0 1 2 0v9.907A6.011 6.011 0 0 0 17.188 19H18v2a1 1 0 0 0 2 0v-2a2 2 0 0 0-2-2Zm-4-8.65a5.922 5.922 0 0 0-.941-.251l-.111-.017a5.52 5.52 0 0 0-1.9 0l-.111.017A5.925 5.925 0 0 0 10 8.35V8a2 2 0 1 1 4 0v.35Z"/>
                        </svg>
                      )}
                      {processStatusMap[claim.adasStatus] === 'pending' && (
                        <svg className="w-5 h-5 m-auto text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clipRule="evenodd"/>
                        </svg>
                      )}
                    </Tooltip>
                  </td>
                  <td className="text-center px-4 py-2.5 border-r border-solid border-neutral-700">
                    {claim.estimatic === 'ccc' && (
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">C</span>
                    )}
                    {claim.mitchellUrlKey && (
                      <a target="_blank" href={`${claim.mitchelUrl}/connect/#/job-overview/${claim.mitchellUrlKey}`} >
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                          M
                        </span>
                      </a>
                    )}
                  </td>
                  <td className="px-4 py-2.5 border-r border-solid border-neutral-700">
                    {claim.vehicleVin && (
                      <span className="list-vin-number"
                        onClick={() => handleCopy(claim.vehicleVin)}>
                        <Tooltip title={copiedString === claim.vehicleVin ? "Copied!" : "Copy VIN Number"} arrow>
                          <span className="vin-number">{claim.vehicleVin && claim.vehicleVin !== 'null' ? claim.vehicleVin : 'N/A'}</span>
                          <svg data-icon-name="copyIcon" viewBox="0 0 18 18" width="18" height="18" aria-hidden="true" sandboxuid="4">
                            <path fill="#fff" fillRule="evenodd" d="M8 7v7h5V7H8zM3.99 2h7.942v2H4.985v8H3V2.995A1 1 0 013.99 2zM6 5.996c0-.55.446-.996.998-.996h7.004c.55 0 .998.445.998.996v9.008c0 .55-.446.996-.998.996H6.998A.996.996 0 016 15.004V5.996z" sandboxuid="4"></path>
                          </svg>
                        </Tooltip>
                      </span>
                    )}
                  </td>
                  <td className="text-right px-4 py-2.5 border-r border-solid border-neutral-700">
                    <span className={claim.tcEstimate !== 0 ? "text-green-600" : "text-slate-300"}>${claim.tcEstimate}</span>
                  </td>
                  <td className="text-right px-4 py-2.5 border-r border-solid border-neutral-700">
                    <span className={claim.estimateApproved ? "text-green-600" : "text-slate-300"}>${claim.humanEstimate}</span>
                  </td>
                  {user.superAdmin && (
                    <td className="px-4 py-2.5 border-r border-solid border-neutral-700">
                      <span className="tooltip-table" onClick={() => handleCopy(claim.user)}>
                        <Tooltip title={copiedString === claim.user ? "Copied!" : claim.user} arrow>
                          <span className="max-90">{claim.user}</span>
                          <svg data-icon-name="copyIcon" viewBox="0 0 18 18" width="18" height="18" aria-hidden="true" sandboxuid="4">
                            <path fill="#fff" fillRule="evenodd" d="M8 7v7h5V7H8zM3.99 2h7.942v2H4.985v8H3V2.995A1 1 0 013.99 2zM6 5.996c0-.55.446-.996.998-.996h7.004c.55 0 .998.445.998.996v9.008c0 .55-.446.996-.998.996H6.998A.996.996 0 016 15.004V5.996z" sandboxuid="4"></path>
                          </svg>
                        </Tooltip>
                      </span>
                    </td>
                  )}
                  <td className="text-center px-4 py-2.5">
                    {claim.createdAt}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          </table>
          {loading ? (
            <div className="p-4 flex align-center justify-between animate-pulse w-full">
              <div className="h-6 w-96 bg-gray-200 rounded-lg dark:bg-gray-700"></div>
              <div className="h-6 w-96 bg-gray-200 rounded-lg dark:bg-gray-700"></div>
            </div>
          ) : (
            <div className="p-4">
              <Toolbar pageCount={pageCount} 
                      activePage={activePage}
                      updatePageSize={updatePageSize}
                      pageSize={pageSize}
                      updateActivePage={updateActivePage}
                      updateUrlParams={updateUrlParams}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}