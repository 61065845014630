import { Switch, Route } from "react-router-dom";
import React, { useState } from "react";
import Subscription from "../components/Account/Subscription";
import TokenHistory from "../components/Account/TokenHistory";
import Config from "../components/Account/Config";
import UpdateProfile from "../components/Account/UpdateProfile";
import UpdatePassword from "../components/Account/UpdatePassword";
import { Helmet } from "react-helmet";
import Header from "../components/Header";

export default function Settings() {
  const menuActive = 'settings';
  const [leftNavVisible, setLeftNavVisible] = useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim App - Settings</title>
      </Helmet>
      <Header 
        leftNavVisible={leftNavVisible} 
        setLeftNavVisible={setLeftNavVisible} 
        menuActive={menuActive}
      />
      <main className={`${leftNavVisible? 'ml-64' : ''} transition-all flex-1 pt-[73px]`}>
        <Switch>
          <Route exact path="/settings" render={() => <Config />} />
          <Route
            path="/settings/subscription"
            render={() => <Subscription />}
          />
          <Route
            path="/settings/token-history"
            render={() => <TokenHistory />}
          />
          <Route
            path="/settings/update-profile"
            render={() => <UpdateProfile />}
          />
          <Route
            path="/settings/update-password"
            render={() => <UpdatePassword />}
          />
        </Switch>
      </main>
    </>
  );
}
