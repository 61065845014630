import { Dots, NextLink, PageLink, Pagination, PreviousLink, Flex, SingleSelectOption, SingleSelect, Typography } from '@strapi/design-system';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import React, { useEffect } from "react";

export default function PaginationURLQuery({ 
  pageCount,
  activePage,
  updatePageSize,
  pageSize,
  updateActivePage,
}) {
  const location = useLocation();
  const boundaryCount = 1;
  const siblingCount = 1;
  const options = [30, 50, 100];

  const formatMessage = (message, values) => {
    return message.defaultMessage;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page'));
    if (!isNaN(page) && page !== activePage) {
      updateActivePage(page);
    }

    const urlPageSize = parseInt(searchParams.get('pageSize'));
    if (!isNaN(urlPageSize) && urlPageSize !== pageSize) {
      updatePageSize(urlPageSize);
    }
  }, [location.search]); // <-- Add location.search as a dependency to react to URL changes

  const range = (start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const startPages = range(1, Math.min(boundaryCount, pageCount));
  const endPages = range(Math.max(pageCount - boundaryCount + 1, boundaryCount + 1), pageCount);

  const siblingsStart = Math.max(
    Math.min(activePage - siblingCount, pageCount - boundaryCount - siblingCount * 2 - 1),
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(activePage + siblingCount, boundaryCount + siblingCount * 2 + 2),
    endPages.length > 0 ? endPages[0] - 2 : pageCount - 1
  );

  const items = [
    ...startPages,
    ...(siblingsStart > boundaryCount + 2 ? ['start-ellipsis'] : boundaryCount + 1 < pageCount - boundaryCount ? [boundaryCount + 1] : []),
    ...range(siblingsStart, siblingsEnd),
    ...(siblingsEnd < pageCount - boundaryCount - 1 ? ['end-ellipsis'] : pageCount - boundaryCount > boundaryCount ? [pageCount - boundaryCount] : []),
    ...endPages,
  ];

  const handleChange = (e) => {
    updatePageSize(e);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('pageSize', e);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  const generateUrl = (page) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page);
    searchParams.set('pageSize', pageSize);
    return `${location.pathname}?${searchParams.toString()}`;
  };

  return (
    <>
      <Flex className="bottom-toolbar" justifyContent="space-between" gap={2}>
        <Flex className="limiter" gap={2}>
          <SingleSelect
            size="S"
            aria-label={formatMessage({ id: 'components.PageFooter.select', defaultMessage: 'Entries per page' })}
            onChange={handleChange}
            value={pageSize}
          >
            {options.map((option) => (
              <SingleSelectOption key={option} value={option}>
                {option}
              </SingleSelectOption>
            ))}
          </SingleSelect>
          <Typography textColor="neutral600" as="span">
            {formatMessage({ id: 'components.PageFooter.select', defaultMessage: 'Entries per page' })}
          </Typography>
        </Flex>
        <Pagination activePage={activePage} pageCount={pageCount}>
          <PreviousLink as={NavLink} active={false} to={generateUrl(activePage - 1)}>
            {formatMessage({ id: 'components.pagination.go-to-previous', defaultMessage: 'Go to previous page' })}
          </PreviousLink>
          {items.map((item) => {
            if (typeof item === 'number') {
              return (
                <PageLink
                  active={item === activePage}
                  key={item}
                  number={item}
                  as={NavLink}
                  to={generateUrl(item)}
                >
                  {formatMessage({ id: 'components.pagination.go-to', defaultMessage: `Go to page ${item}` }, { page: item })}
                </PageLink>
              );
            }
            return <Dots key={item} />;
          })}
          <NextLink as={NavLink} active={false} to={generateUrl(activePage + 1)}>
            {formatMessage({ id: 'components.pagination.go-to-next', defaultMessage: 'Go to next page' })}
          </NextLink>
        </Pagination>
      </Flex>
    </>
  );
};