import IntergrationCCC from "../../components/Account/IntergrationCCC";
import { Layout} from "@strapi/design-system";

export default function AccountSettingsIntergrations({ typeAccount }) {
  const expandedSection = 'acc-2';

  return (
    <div>
      <Layout>
        {typeAccount === 'ccc' && (
          <IntergrationCCC />
        )}
      </Layout>
    </div>
  );
}
